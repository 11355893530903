import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  featuredList: [],
  errorMessage: '',
  paginatedElements: 0,
  search: {
    data: [],
    errorMessage: '',
    paginatedElements: 0,
    loadingNext: false,
    currentPage: 1
  }
};

const marketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.MARKETPLACE_FEATURED_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };

    case REDUX_ACTIONS.MARKETPLACE_FEATURED_LIST_SUCCESS:
      return {
        ...state,
        featuredList: [...action.payload],
        loading: false,
        errorMessage: ''
      };

    case REDUX_ACTIONS.MARKETPLACE_SEARCH_LOADING:
      return {
        ...state,
        search: {
          ...state.search,
          loading: true,
          errorMessage: ''
        }
      };

    case REDUX_ACTIONS.MARKETPLACE_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          data: action.payload.currentPage === 1 ? [...action.payload.data] : [...state.search.data, ...action.payload.data],
          paginatedElements: action.payload.paginatedElements,
          currentPage: action.payload.currentPage,
          loadingNext: false,
          loading: false,
          errorMessage: ''
        }
      };

    case REDUX_ACTIONS.MARKETPLACE_SEARCH_LOAD_NEXT:
      return {
        ...state,
        search: {
          ...state.search,
          loadingNext: true,
        }
      };

    case REDUX_ACTIONS.MARKETPLACE_SEARCH_ERROR:
      return {
        ...state,
        search: {
          ...state.search,
          data: [],
          loading: false,
          errorMessage: action.payload
        }
      };

    case REDUX_ACTIONS.MARKETPLACE_SEARCH_RESET:
      return {
        ...state,
        search: {
          ...state.search,
          data: [],
          loading: false,
          errorMessage: '',
          paginatedElements: 0,
          currentPage: 1,
          loadingNext: false,
        }
      };

    default:
      return state;
  }
};

export default marketplaceReducer;
