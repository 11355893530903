import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';
import RigManager1 from '../../../assets/images/RigManager1.png';
import RigManager2 from '../../../assets/images/RigManager2.png';
import RigManager3 from '../../../assets/images/RigManager3.png';
import { MKT_STORYBOOK_DESCRIPTION } from '../../../utils/constants';

const StoryBook = () => {
  const classes = styles();

  const icons = [RigManager1, RigManager2, RigManager3];

  return (
      <Grid className={classes.storyBookContainer}>
        <Typography variant='h3' className={classes.storybookTitle}>Let Us Help You!</Typography>
       <Box className={classes.cardContainer}>
       <Grid container spacing={3} justifyContent='center'>
          {MKT_STORYBOOK_DESCRIPTION.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card className={classes.card}>
               <img src={icons[index]} alt={item.title} className={classes.icon} />
                  <Typography variant='h5' fontWeight='600' className={classes.descriptionStyle}>{item.description}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
       </Box>
      </Grid>
  );
};
export default StoryBook;
