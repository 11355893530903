import React, { useEffect, useRef, useMemo, createRef, useState } from 'react';
import { Resizable } from 're-resizable';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
  Polyline,
  ZoomControl
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import clsx from 'clsx';
import { Box, Grid, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Icon } from 'leaflet';
import { alpha, styled } from '@mui/material/styles';
import { blue, grey, red } from '@mui/material/colors';
import pinRed from '../../../assets/images/pin_red.png';
import { isMobile as mobileView } from '../../../utils/isMobile';
import { REDUX_ACTIONS } from '../../../shared/config/constants';

const zoomLevel = 4;
const initalPosition = [39.5, -98.35];

// adding static well data temporarily for page content - remove once backend flow for wells is ready

const OpenStreetMap = ({
  mapData,
  markerData,
  getMapPin,
  mapPinField1,
  mapPinField2,
  latitude,
  longitude,
  popUpLabel,
  getAssetTypesLabel,
  rowId,
  getAssetType,
  setIconPinSize,
  referenceKey,
  mapData2,
  markerData2,
  latitudeField2,
  longitudeField2,
  getMapPin2,
  secondaryPopup,
  isMobile,
  onMarkerClick,
  excludePopupLabel,
  rigData,
  polylinePositions,
  selectedPlant,
  showMaxDate,
  showRigsToggleButton,
  showCheckinsToggleButton,
  showRigPinsByDefault,
  mapData3,
  markerData3,
  mapPinField3,
  tertiaryPopup,
  getMapPin3,
  latitudeField3,
  longitudeField3,
  showDbwTargetToggleButtons,
  showCheckinsPinsByDefault,
}) => {
  const classes = styles();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.currentUser);
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(zoomLevel);
  const [position, setPosition] = useState(initalPosition);
  const selectedPlantRef = useRef(selectedPlant);
  const selectedRigRef = useRef(null);
  const [showRigPins, setShowRigPins] = useState(showRigPinsByDefault);
  const [showCheckinsPins, setShowCheckinsPins] = useState(showCheckinsPinsByDefault);
  const { showDbwPins } = useSelector((state) => state.dbwVsTarget);

  const mobile = mobileView();
  const rigSwitchColorTheme = showRigPinsByDefault ? red : blue;
  const DbwTargetPins = ['MPE DBW', 'NON-MPE DBW', 'DBW', 'TARGET'];

  const markersRef = useMemo(() => {
    const refs = {};
    markerData.forEach((row) => {
      refs[`marker_${row[referenceKey]}`] = createRef(null);
    });
    if (markerData2) {
      markerData2.forEach((row) => {
        refs[`marker2_${row[referenceKey]}`] = createRef(null);
      });
    }
    if (markerData3) {
      markerData3.forEach((row) => {
        refs[`marker3_${row[referenceKey]}`] = createRef(null);
      });
    }

    return refs;
  }, [markerData, markerData2, markerData3]);

  useEffect(() => {
    if (rigData) {
      if (!selectedPlant) {
        const marker = markersRef[`marker_${selectedPlantRef.current?.Plant1}`];
        marker?.current?.closePopup();
      } else {
        selectedPlantRef.current = selectedPlant;
      }
    }
  }, [selectedPlant]);

  useEffect(() => {
    if (rigData?.RigName_Number) {
      const map = mapRef.current;
      if (!map) {
        return;
      }
      if (selectedRigRef.current) {
        selectedRigRef.current?.openPopup();
      }
    }
  }, [rigData]);

  useEffect(() => {
    // this effect is used to update the map size when the user changes viewport to widescreen
    handleMapResize();
  }, [user.preferences.aspectRatioPage]);

  useEffect(() => {
    if (mapRef?.current?._zoom && setIconPinSize) {
      setIconPinSize([mapRef?.current?._zoom * 3.5, mapRef?.current?._zoom * 5.75]);
    }
  }, [mapRef?.current?._zoom]);

  useEffect(() => {
    showMarker(rowId);
  }, [rowId]);

  function MapView() {
    const map = useMapEvent('moveend', () => {
      // Sets geographical center and zoom for the view of the map
      setZoom(map.getZoom());
      setPosition(map.getCenter());
    });
    return null;
  }

  const showMarker = (data) => {
    const map = mapRef.current;
    if (!map || !data) {
      return;
    }

    // map.flyTo(
    //   [parseFloat(data[latitude]), parseFloat(data[longitude])],
    //   map.getZoom()
    // );
    const marker = markersRef[`marker_${data[referenceKey]}`] || markersRef[`marker2_${data[referenceKey]}`] || markersRef[`marker3_${data[referenceKey]}`];
    if (marker) {
      marker.current?.openPopup();
    }
  };

  const handleMapResize = () => {
    if (!mapRef?.current?.invalidateSize) return;
    mapRef.current.invalidateSize();
  };

  const getRigIcon = () => {
    return new Icon({
      iconUrl: pinRed,
      iconSize: [25, 35],
      popupAnchor: [0, -5],
    });
  };

  if (isMobile) {
    return (
      <Resizable
      minWidth={'100vw'}
      minHeight={'20vh'}
      maxHeight={'50vh'}
      enable={{
        top: false,
        right: false,
        bottom: true,
        left: false,
      }}
      handleComponent={{
        bottom: <DragHandleIcon />,
      }}
      style={{
        marginBottom: '20px'
      }}
      handleWrapperStyle={{
        backgroundColor: 'white',
        padding: '8px 0',
      }}
      handleStyles={{
        bottom: {
          textAlign: 'center',
        }
      }}
      onResizeStop={handleMapResize}
    >
       <MapContainer
          style={{
            height: '100%',
            width: '100%'
          }}
          zoom={zoom}
          center={position}
          scrollWheelZoom={true}
          whenCreated={(map) => {
            mapRef.current = map;
          }}
          attributionControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {mapData.map((row) => (
            <Marker
              key={row[referenceKey]}
              icon={getMapPin(row[mapPinField1], row[mapPinField2], row[mapPinField3])}
              position={[parseFloat(row[latitude]), parseFloat(row[longitude])]}
              ref={markersRef[`marker_${row[referenceKey]}`]}
              eventHandlers={{
                click: () => onMarkerClick(row[referenceKey]),
              }}
            >
              {
                <Popup>
                <Box className={classes.popupBox}>
                {popUpLabel && popUpLabel.map((label, index) => (
                <div key={index}>
                 {!excludePopupLabel && <span>{label.toUpperCase()}:</span>} {row[label]}
               </div>
                ))}
       {getAssetType && <div>{getAssetType.toUpperCase()}: {getAssetTypesLabel(row[getAssetType])}</div>}
       {showMaxDate && (<div>{new Date(row[showMaxDate[0]]) > new Date(row[showMaxDate[1]]) ? row[showMaxDate[0]] || '' : row[showMaxDate[1]] || ''}</div>)}
        </Box>
       </Popup>
              }
            </Marker>
          ))}
          <MapView />
        </MapContainer>
    </Resizable>
    );
  }

  const ColoredRigSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: rigSwitchColorTheme[600],
      '&:hover': {
        backgroundColor: alpha(rigSwitchColorTheme[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: rigSwitchColorTheme[600],
    },
  }));

  const RigsSwitchButton = () => {
    return (
      <>
        {showRigsToggleButton && mapData2?.length > 0 && (
          <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ width: showCheckinsToggleButton && mapData3?.length > 0 ? '100%' : 'auto' }}>
            <Typography marginLeft={'0.5rem'} fontWeight={'900'}>Rigs</Typography>
            <ColoredRigSwitch
              checked={showRigPins}
              onClick={() => setShowRigPins(!showRigPins)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        )}
      </>
    );
  };

  const ColoredCheckinsSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: blue[900],
      '&:hover': {
        backgroundColor: alpha(blue[900], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[900],
    },
  }));

  const CheckinsSwitchButton = () => {
    return (
      <>
        {showCheckinsToggleButton && mapData3?.length > 0 && (
          <Box display='flex' alignItems='center' className={showDbwTargetToggleButtons ? classes.dbwCheckinsButton : ''}>
            <Typography marginLeft={'0.5rem'} fontWeight={'900'}>Check-Ins</Typography>
            <Box className={showDbwTargetToggleButtons ? classes.dbwCheckinsSwitchButton : ''}>
            <ColoredCheckinsSwitch
              checked={showCheckinsPins}
              onClick={() => setShowCheckinsPins(!showCheckinsPins)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            </Box>
          </Box>
        )}
      </>
    );
  };
  const handleToggle = (pin) => {
    const newShowDbwPins = {
      ...showDbwPins,
      [pin]: !showDbwPins[pin]
    };
    dispatch({
      type: REDUX_ACTIONS.DBW_VS_TARGET_MARKER_DATA,
      payload: newShowDbwPins
    });
  };

  const ColoredDbwTargetSwitch = styled(Switch)(({ theme, pin }) => {
    const getColor = (pin) => {
      switch (pin) {
        case 'TARGET':
          return blue[300];
        case 'MPE DBW':
        case 'NON-MPE DBW':
        case 'DBW':
          return grey[600];
        default:
          return theme.palette.primary.main;
      }
    };

    const color = getColor(pin);

    return {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color,
        '&:hover': {
          backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: color,
      },
    };
  });

  const DBWvsTargetSwitchButton = () => {
    return (
      <>
     {showDbwTargetToggleButtons && (
        <Box display='flex-column' alignItems='center'>
          {DbwTargetPins.map((pin, index) => (
            <Box key={index} display='flex' alignItems='center' marginLeft={'1rem'} justifyContent='space-between'>
              <Typography fontWeight={'800'}>{pin}</Typography>
              <ColoredDbwTargetSwitch
                pin={pin}
                checked={showDbwPins[pin]}
                onClick={() => handleToggle(pin)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
          ))}
        </Box>
     )}
    </>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <MapContainer
          closePopupOnClick={false}
          className={clsx(classes.map)}
          zoom={zoom}
          center={position}
          scrollWheelZoom={true}
          whenCreated={(map) => {
            mapRef.current = map;
          }}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Box className={classes.switchButton}
            sx={{
              top: mobile ? '180px' : 10,
            }}>
            <RigsSwitchButton />
            <DBWvsTargetSwitchButton/>
            <CheckinsSwitchButton />
          </Box>
          <ZoomControl position='bottomright' />
          {mapData.map((row) => (
            <Marker
              autoPanOnFocus={false}
              key={row[referenceKey]}
              icon={getMapPin(row[mapPinField1], row[mapPinField2], row[mapPinField3])}
              position={[parseFloat(row[latitude]), parseFloat(row[longitude])]}
              ref={markersRef[`marker_${row[referenceKey]}`]}
              eventHandlers={{
                click: () => {
                  if (onMarkerClick) {
                    onMarkerClick(row);
                  }
                },
              }}
            >
                <Popup>
                  <Box className={classes.popupBox}>
                    {popUpLabel && popUpLabel.map((label, index) => (
                      <div key={index}>
                        {!excludePopupLabel && <span>{label.toUpperCase()}:</span>}
                        {label?.includes('Spend') && <span>{label.toUpperCase()}: </span>}
                        {label?.includes('Spend') && '$'}{row[label]}
                      </div>
                    ))}
                    {getAssetType && <div>{getAssetType.toUpperCase()}: {getAssetTypesLabel(row[getAssetType])}</div>}
                    {showMaxDate && (<div>{new Date(row[showMaxDate[0]]) > new Date(row[showMaxDate[1]]) ? row[showMaxDate[0]] || '' : row[showMaxDate[1]] || ''}</div>)}
                  </Box>
                </Popup>
              {rigData && (
                <Popup
                  autoPan={false}
                >
                  <div>
                    {row[popUpLabel[0]]}
                    {polylinePositions?.distance && (<>  ({polylinePositions?.distance.toFixed(2)} miles)</>)}
                    {rowId
                      ? (
                        <>
                          <br></br>
                          {rowId?.PartNum} ({rowId?.OnHandQty})
                        </>
                        )
                      : null}
                  </div>
                </Popup>
              )}
            </Marker>
          ))}
          {rigData?.RigName_Number
            ? (
              <Marker
                autoPanOnFocus={false}
                key={rigData._id}
                icon={getRigIcon()}
                position={[parseFloat(rigData?.RigLatitudeWGS84), parseFloat(rigData?.RigLongitudeWGS84)]}
                ref={selectedRigRef}
              >
                <Popup
                  autoPan={false}
                  autoClose={false}
                  closeButton={false}
                  closeOnClick={false}
                  closeOnEscapeKey={false}
                >{rigData?.RigName_Number}
                </Popup>
              </Marker>
              )
            : null}
           {rigData?.RigName_Number && selectedPlant?.Plant1 && polylinePositions?.polylineData.length && (
              <Polyline positions={polylinePositions?.polylineData} pathOptions={{ color: 'red' }} />
           )}
          {showRigPins && mapData2 && mapData2.map((row) => (
            <Marker
              key={row[referenceKey]}
              icon={getMapPin2(row[mapPinField1], row[mapPinField2], row[mapPinField3])}
              position={[parseFloat(row[latitudeField2]), parseFloat(row[longitudeField2])]}
              ref={markersRef[`marker2_${row[referenceKey]}`]}
            >
              <Popup>
                {secondaryPopup && `${secondaryPopup.toUpperCase()}: ${row[secondaryPopup]}`}
              </Popup>
            </Marker>
          ))}
           {showCheckinsPins && mapData3 && mapData3.map((row) => (
            <Marker
              key={row[referenceKey]}
              icon={getMapPin3(row[mapPinField3])}
              position={[parseFloat(row[latitudeField3]), parseFloat(row[longitudeField3])]}
              ref={markersRef[`marker3_${row[referenceKey]}`]}
            >
              <Popup>
                 <Box className={classes.popupBox}>
                   {tertiaryPopup && tertiaryPopup.map((label, index) => (
                     <div key={index}>
                       {!excludePopupLabel && <span>{label.toUpperCase()}:</span>} {row[label]}
                     </div>
                   ))}
                 </Box>
              </Popup>
            </Marker>
           ))}
          <MapView />
        </MapContainer>
      </Grid>
    </Grid>
  );
};

OpenStreetMap.propTypes = {
  mapData: PropTypes.array,
  markerData: PropTypes.array,
  getMapPin: PropTypes.func,
  mapPinField1: PropTypes.string,
  mapPinField2: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  popUpLabel: PropTypes.any,
  getAssetTypesLabel: PropTypes.func,
  rowId: PropTypes.object,
  getAssetType: PropTypes.any,
  setIconPinSize: PropTypes.func,
  referenceKey: PropTypes.string,
  mapData2: PropTypes.any,
  markerData2: PropTypes.any,
  latitudeField2: PropTypes.string,
  longitudeField2: PropTypes.string,
  getMapPin2: PropTypes.func,
  secondaryPopup: PropTypes.string,
  isMobile: PropTypes.bool,
  onMarkerClick: PropTypes.any,
  excludePopupLabel: PropTypes.bool,
  rigData: PropTypes.object,
  polylinePositions: PropTypes.object,
  selectedPlant: PropTypes.object,
  showMaxDate: PropTypes.any,
  showRigsToggleButton: PropTypes.bool,
  showCheckinsToggleButton: PropTypes.bool,
  showRigPinsByDefault: PropTypes.bool,
  showCheckinsPinsByDefault: PropTypes.bool,
  mapData3: PropTypes.any,
  markerData3: PropTypes.any,
  mapPinField3: PropTypes.string,
  tertiaryPopup: PropTypes.any,
  getMapPin3: PropTypes.func,
  latitudeField3: PropTypes.string,
  longitudeField3: PropTypes.string,
  showDbwTargetToggleButtons: PropTypes.bool,
};

export default OpenStreetMap;
