import fetchAPI from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';
import store from '../store';
import * as ENVIRONMENT from '../../shared/config/config';
import { USER_TYPE } from '../../utils/constants';

/* prettier-ignore */
export const listParts = (customerRigId, idList = []) => (dispatch) => {
  const { currentUser } = store.getState();
  const redirectTypesToUsers = [USER_TYPE.CUSTOMER_ADMIN, USER_TYPE.CUSTOMER_MANAGER];

  dispatch({
    type: REDUX_ACTIONS.PARTS_LIST_LOADING,
  });

  const collectionName = 'Parts';

  const body = { collectionName, idList, customerRigId };

  return fetchAPI
    .post('parts/parts', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }

      return resp.json();
    })
    .then(async (data) => {
      dispatch({
        type: REDUX_ACTIONS.PARTS_LIST_SUCCESS,
        payload: {
          parts: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      let errorMessage = await parseError(error);
      const errorPayload = { ...error, message: errorMessage, redirectToUsers: false };

      if (error.status === 404 && errorMessage.includes('No items found') && redirectTypesToUsers.includes(currentUser.type)) {
        errorPayload.redirectToUsers = true;
        errorMessage = 'No items found in Shop. Redirecting to Users Page';
      }

      dispatch(enqueueSnackbar(errorMessage, 'error', new Date().getTime() + Math.random()));
      dispatch({
        type: REDUX_ACTIONS.PARTS_LIST_ERROR,
        payload: errorPayload,
      });
    });
};

export const listPartsSellerCompany = (companyId, vendorId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.PARTS_LIST_LOADING,
  });

  const body = { companyId, vendorId };

  const postRequest = 'parts/partsSellerCompany';

  return fetchAPI
    .post(postRequest, body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }

      return resp.json();
    })
    .then(async (data) => {
      dispatch({
        type: REDUX_ACTIONS.PARTS_LIST_SUCCESS,
        payload: {
          parts: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.PARTS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const tooglePartClassification =
  (companyId, partId, newClassification) => (dispatch) => {
    dispatch({
      type: REDUX_ACTIONS.PARTS_LIST_SAVING,
      payload: { partId },
    });
    const body = {
      partId,
      newClassification,
      companyId,
    };

    fetchAPI
      .put('parts/classification', body)
      .then((result) => {
        if (!result.ok) {
          return Promise.reject(result);
        }
        dispatch({
          type: REDUX_ACTIONS.PARTS_SET_CLASSIFICATION,
          payload: { partId, classification: newClassification },
        });
      })
      .catch(async (error) => {
        const errorMessage = await parseError(error);
        dispatch(
          enqueueSnackbar(
            errorMessage,
            'error',
            new Date().getTime() + Math.random()
          )
        );
      });
  };

export const partCategories = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.PART_CATEGORIES_GET_LOADING,
  });
  const collectionName = 'Parts';

  const body = { collectionName };
  return fetchAPI
    .post('parts/partCategories', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }

      return resp.json();
    })
    .then(async (data) => {
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_GET_SUCCESS,
        payload: data.data,
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_GET_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const updatePartPrice = (partId, part, companyId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.PARTS_LIST_SAVING,
    payload: { partId },
  });

  const body = {
    partId,
    part,
    companyId,
  };

  fetchAPI
    .put('parts/part', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      dispatch({
        type: REDUX_ACTIONS.PARTS_SET_PRICE,
        payload: { partId, price: part.Price },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.PARTS_SET_PRICE_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const updateCostCode = (partId, costCode, companyId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.PARTS_LIST_SAVING,
    payload: { partId },
  });

  const body = {
    collectionName: 'Parts',
    partId,
    costCode,
    companyId,
  };

  fetchAPI
    .put('parts/partCostCode', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.PARTS_SET_PRICE_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const mailPart = (emailAddress, part) => (dispatch) => {
  const body = {
    emails: emailAddress,
    part
  };

  return fetchAPI
    .post('marketplace/contact', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Mail Sent Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const listPartsByCustomer = (Id) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.PARTS_CUSTOMER_LIST_LOADING,
  });

  const body = { Id };

  return fetchAPI
    .post('parts/inventory', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }

      return resp.json();
    })
    .then(async (data) => {
      dispatch({
        type: REDUX_ACTIONS.PARTS_CUSTOMER_LIST_SUCCESS,
        payload: {
          parts: data.data,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(enqueueSnackbar(errorMessage, 'error', new Date().getTime() + Math.random()));

      dispatch({
        type: REDUX_ACTIONS.PARTS_CUSTOMER_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const partsCsvUpload = (csv, id, setIsLoading, handleUploadSuccess) => async (dispatch) => {
  const formData = new FormData();
  const { currentUser } = store.getState();
  const BASE_URL = ENVIRONMENT.API_URL;
  const payload = {
    query: {
      companyId: id,
    },
    user: {
      type: currentUser.type,
      companyId: currentUser.companyId,
      userId: currentUser.userId
    }
  };
  formData.append('file', csv);
  formData.append('payload', JSON.stringify(payload));
  setIsLoading(true);
  try {
    const response = await fetch(`${BASE_URL}parts/importCSV`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    if (!response.ok) {
      const err = await response.json();
      throw new Error(err.message);
    }
    setIsLoading(false);
    handleUploadSuccess();
  } catch (error) {
    setIsLoading(false);
    dispatch(
      enqueueSnackbar(
        error.message || error || 'Error uploading file',
        'error',
        new Date().getTime() + Math.random()
      )
    );
    return Promise.reject(error);
  }
};
