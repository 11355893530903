export const getDateInfo = (date, separator) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  const fullTime = date.toTimeString().split(':').join(':');
  const timeData = fullTime.split(' ');
  const shortTime = timeData[0];

  return {
    formated: month + separator + day + separator + year,
    time: shortTime,
    timeData,
    formattedDayTime: month + separator + day + separator + year + ' ' + shortTime
  };
};

export const getLastTwoWeeksDateRange = () => {
  const now = new Date(); // Current date and time

  const twoWeeksAgo = new Date(now);
  twoWeeksAgo.setDate(now.getDate() - 14);

  const selectedStartDateTime = twoWeeksAgo.toISOString();
  const selectedEndDateTime = now.toISOString();

  return {
    selectedStartDateTime,
    selectedEndDateTime
  };
};

export const getUTCOffset = () => {
  const now = new Date();
  const offsetMinutes = now.getTimezoneOffset();
  const totalMinutes = Math.abs(offsetMinutes);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const sign = offsetMinutes <= 0 ? '+' : '-';
  const formattedOffset = `${sign}${hours}:${minutes.toString().padStart(2, '0')}`;

  return formattedOffset;
};
