const columns = (renderCell, renderActions) => [
  {
    field: 'mw',
    headerName: 'MW (ppg)',
    width: 112,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_600',
    headerName: 'FANN600',
    width: 112,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_300',
    headerName: 'FANN300',
    width: 112,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_200',
    headerName: 'FANN200',
    width: 112,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_100',
    headerName: 'FANN100',
    width: 120,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_6',
    headerName: 'FANN6',
    width: 120,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'fann_3',
    headerName: 'FANN3',
    width: 120,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'pv',
    headerName: 'PV (cp)',
    width: 120,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => {
      if (params?.row?.fann_600 && params?.row?.fann_300 && (params?.row?.fann_300 < params?.row?.fann_600) && (params?.row?.fann_200 < params?.row?.fann_300)) {
        const pv = params.row.fann_600 - params.row.fann_300;
        return pv.toFixed(2);
      }
      return '';
    }
  },
  {
    field: 'yp',
    headerName: 'YP (lb/100ft²)',
    width: 150,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => {
      if (params?.row?.fann_300 && params?.row?.fann_600 && (params?.row?.fann_300 < params?.row?.fann_600) && (params?.row?.fann_200 < params?.row?.fann_300)) {
        const yp = params.row.fann_300 - (params.row.fann_600 - params.row.fann_300);
        return yp.toFixed(2);
      }
      return '';
    }
  },
  {
    field: 'gel_10min',
    headerName: 'GEL 10 MIN (lb/100ft²)',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'gel_10sec',
    headerName: 'GEL 10 SEC (lb/100ft²)',
    width: 220,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'tank_vol',
    headerName: 'TANK VOLUME (bbl)',
    width: 200,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    type: 'number'
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
