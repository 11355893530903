import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, Typography } from '@mui/material';
import styles from './style';
import AccountCircle from '../../../../assets/images/account-circle.svg';

function LiveHelpSalesRepsMobile({ data, loading }) {
  const classes = styles();

  const SalesRepGridItem = ({ rowData }) => {
    const image = rowData?.profilePic;

    return (
        <Grid container alignItems='center' className={classes.gridItemContainer}>
             <img
            src={image || AccountCircle}
            className={classes.gridItemImage}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AccountCircle;
            }}
            />
            <Grid item>
            <Typography className={classes.gridItemText} sx={{ fontWeight: 'bold' }}>
                    {rowData?.firstName}
                </Typography>
                <Typography className={classes.gridItemText} sx={{ fontWeight: 'bold' }}>
                    {rowData?.emailAddress}
                </Typography>
                <Typography className={classes.gridItemText}>
                    {rowData?.phone}
                </Typography>
            </Grid>
        </Grid>
    );
  };

  return (
   <Grid>
    {loading
      ? (
        <Grid className={classes.loadingContainer}>
            <CircularProgress />
        </Grid>
        )
      : (
        <Grid sx={{ pl: 2, pr: 2 }}>
        {data?.map((item) => {
          return (
           <SalesRepGridItem key={item.grid_id} rowData={item} />
          );
        })}
        </Grid>
        )}
    </Grid>
  );
}

LiveHelpSalesRepsMobile.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  rowData: PropTypes.object
};

LiveHelpSalesRepsMobile.defaultProps = {
  data: [],
  loading: true,
  rowData: {}
};

export default LiveHelpSalesRepsMobile;
