import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  root: {
    maxWidth: 345,
    margin: 'auto',
    borderRadius: 2,
    boxShadow: '2px 2px 5px #CCC',
    transition: 'box-shadow 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 8px #666',
    }
  },
  comment: {
    padding: 10,
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }
}));
export default styles;
