import React, { useEffect } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, IconButton, Box } from '@mui/material';
import ModalDialog from '../../ModalDialog';
import { CancelPresentationRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Datagrid from '../../Datagrid';
import columns from './table_config';
import { renderCell } from '../../Datagrid/table_config';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSalesReps } from '../../../../store/actions/users';
import { isMobile } from 'react-device-detect';
import LiveHelpSalesRepsMobile from '../../../mobile/ui/LiveHelpSalesRepMobile';
import AccountCircle from '../../../../assets/images/account-circle.svg';

const LiveHelpSalesReps = ({
  handleClose,
  type,
  open
}) => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const salesReps = useSelector((state) => state.users.salesReps);

  useEffect(() => {
    dispatch(fetchSalesReps(type));
  }, []);

  const getModalTitle = () => {
    return (
            <>
                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <IconButton
                            onClick={handleClose}
                        >
                            <CancelPresentationRounded fontSize='large' />
                        </IconButton>
                    </Grid>
                </Grid>
            </>
    );
  };

  const renderProfileImage = (props) => {
    const image = props.row?.profilePic;
    return (
        <Box>
            <img
            src={image || AccountCircle}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AccountCircle;
            }}
            style={{ width: '3em', height: '3em', borderRadius: '100%', objectFit: 'cover', }}
            />
        </Box>
    );
  };

  return (
      <ModalDialog
          title={getModalTitle()}
          isOpen={open}
          onClose={handleClose}
          maxWidth='md'
      >
          <Grid container>
              <Grid item xs={12}>
                {isMobile
                  ? (
                     <LiveHelpSalesRepsMobile data={salesReps?.data} loading={salesReps?.loading} />
                    )
                  : (
                    <Datagrid
                    apiRef={apiRef}
                    data={salesReps?.data}
                    getRowId={(row) => row._id}
                    columns={columns(renderCell, renderProfileImage)}
                    disableStickyHeader={false}
                    autoHeight={false}
                    height={'50vh'}
                    pageSize={15}
                    loading={salesReps?.loading}
                    toolbar={{
                      options: {
                        columns: false,
                        filters: false,
                        density: false,
                        export: false
                      },
                    }}
                />
                    )}
              </Grid>
          </Grid>
      </ModalDialog>
  );
};

LiveHelpSalesReps.propTypes = {
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  open: PropTypes.bool.isRequired,
  row: PropTypes.object
};

export default LiveHelpSalesReps;
