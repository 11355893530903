import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useJsApiLoader } from '@react-google-maps/api';
import GoogleMapView from './googlemap';
import OpenStreetMap from './openstreetmap';
import { GOOGLE_MAPS_KEY, MAP_TYPES } from '../../shared/config/constants';

const Map = ({
  data,
  mapData,
  markerData,
  latitudeField,
  longitudeField,
  mapPinField1,
  mapPinField2,
  popUpLabel,
  getAssetType,
  getAssetTypesLabel,
  getGoogleMapPin,
  getMapPin,
  gmapRowId,
  rowId,
  mapType,
  setIconPinSize,
  referenceKey,
  mapData2,
  markerData2,
  latitudeField2,
  longitudeField2,
  getMapPin2,
  secondaryPopup,
  isMobile,
  onMarkerClick,
  excludePopupLabel,
  rigData,
  polylinePositions,
  selectedPlant,
  showMaxDate,
  showRigsToggleButton,
  showCheckinsToggleButton,
  showRigPinsByDefault,
  showCheckinsPinsByDefault,
  mapData3,
  markerData3,
  mapPinField3,
  tertiaryPopup,
  getMapPin3,
  latitudeField3,
  longitudeField3,
  showDbwTargetToggleButtons,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_KEY
  });

  return (
    <Grid container justifyContent="center">
    {mapType === MAP_TYPES.GOOGLEMAP
      ? <GoogleMapView
        isLoaded={isLoaded}
        data={data}
        mapData={mapData}
        rowId={gmapRowId}
        latitudeField={latitudeField}
        longitudeField={longitudeField}
        mapPinField1={mapPinField1}
        mapPinField2={mapPinField2}
        popUpLabel={popUpLabel}
        getAssetType={getAssetType}
        getAssetTypesLabel={getAssetTypesLabel}
        getMapPin={getGoogleMapPin}
        setIconPinSize={setIconPinSize}
        referenceKey={referenceKey}
        onMarkerClick={onMarkerClick}
        excludePopupLabel={excludePopupLabel}
        showMaxDate={showMaxDate}
      />
      : <OpenStreetMap
        mapData={mapData}
        markerData={markerData}
        getMapPin={getMapPin}
        mapPinField1={mapPinField1}
        mapPinField2={mapPinField2}
        latitude={latitudeField}
        longitude={longitudeField}
        popUpLabel={popUpLabel}
        getAssetTypesLabel={getAssetTypesLabel}
        rowId={rowId}
        getAssetType={getAssetType}
        setIconPinSize={setIconPinSize}
        referenceKey={referenceKey}
        mapData2={mapData2}
        markerData2={markerData2}
        latitudeField2={latitudeField2}
        longitudeField2={longitudeField2}
        getMapPin2={getMapPin2}
        secondaryPopup={secondaryPopup}
        isMobile={isMobile}
        onMarkerClick={onMarkerClick}
        excludePopupLabel={excludePopupLabel}
        rigData={rigData}
        polylinePositions={polylinePositions}
        selectedPlant={selectedPlant}
        showMaxDate={showMaxDate}
        showRigsToggleButton={showRigsToggleButton}
        showCheckinsToggleButton={showCheckinsToggleButton}
        showRigPinsByDefault={showRigPinsByDefault}
        mapData3={mapData3}
        markerData3={markerData3}
        mapPinField3={mapPinField3}
        tertiaryPopup={tertiaryPopup}
        getMapPin3={getMapPin3}
        latitudeField3={latitudeField3}
        longitudeField3={longitudeField3}
        showDbwTargetToggleButtons= {showDbwTargetToggleButtons}
        showCheckinsPinsByDefault={showCheckinsPinsByDefault}
      />}
    </Grid>
  );
};

Map.propTypes = {
  data: PropTypes.any,
  mapData: PropTypes.any,
  rowId: PropTypes.object,
  gmapRowId: PropTypes.string,
  latitudeField: PropTypes.string,
  longitudeField: PropTypes.string,
  mapPinField1: PropTypes.string,
  mapPinField2: PropTypes.string,
  popUpLabel: PropTypes.any,
  getMapPin: PropTypes.func,
  getAssetTypesLabel: PropTypes.func,
  getAssetType: PropTypes.any,
  markerData: PropTypes.any,
  getGoogleMapPin: PropTypes.func,
  mapType: PropTypes.string,
  setIconPinSize: PropTypes.func,
  referenceKey: PropTypes.string,
  mapData2: PropTypes.any,
  markerData2: PropTypes.any,
  latitudeField2: PropTypes.string,
  longitudeField2: PropTypes.string,
  getMapPin2: PropTypes.func,
  secondaryPopup: PropTypes.string,
  isMobile: PropTypes.bool,
  onMarkerClick: PropTypes.any,
  excludePopupLabel: PropTypes.bool,
  rigData: PropTypes.object,
  polylinePositions: PropTypes.object,
  selectedPlant: PropTypes.object,
  showMaxDate: PropTypes.any,
  showRigsToggleButton: PropTypes.bool,
  showCheckinsToggleButton: PropTypes.bool,
  showRigPinsByDefault: PropTypes.bool,
  mapData3: PropTypes.any,
  markerData3: PropTypes.any,
  mapPinField3: PropTypes.string,
  tertiaryPopup: PropTypes.any,
  getMapPin3: PropTypes.func,
  latitudeField3: PropTypes.string,
  longitudeField3: PropTypes.string,
  showDbwTargetToggleButtons: PropTypes.bool,
  showCheckinsPinsByDefault: PropTypes.bool,
};

Map.defaultProps = {
  referenceKey: '_id',
  isMobile: false
};

export default Map;
