import { useEffect, useState } from 'react';

const useScrollEnd = (ref, threshold = 100) => {
  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      setIsNearBottom(scrollTop + clientHeight >= scrollHeight - threshold);
    }
  };

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref]);

  return isNearBottom;
};

export default useScrollEnd;
