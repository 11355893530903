/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { USER_TYPE } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { addNewUser, listUsers, updateUserDetails } from '../../../store/actions/users';
import { listCompanies, listSellersUsers } from '../../../store/actions/companies';
import Datagrid from '../../ui/Datagrid';
import { renderCell } from '../../ui/Datagrid/table_config';
import columns from './table_config';
import { useAuth } from '../../../hooks/useAuth';
import { Grid, IconButton } from '@mui/material';
import UserCartButton from '../../ui/CartButton';
import UsersOrders from '../../usersOrders';
import UsersQuotes from './../../usersQuotes/index';
import { BarChart, Person, PlaylistAddCheck, SaveRounded, SellRounded, WidgetsRounded } from '@mui/icons-material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import SelectEditCompanyCell from '../../ui/SelectEditCompanyCell';
import { enqueueSnackbar } from '../../../store/actions/snackbar';
import { validateEmail } from '../../../utils/validateEmail';
import SellersModalDialog from '../../ui/SellersModalDialog';
import SelectEditInputTextField from '../../ui/SelectEditInputTextField';
import SelectEditValueTypes from '../../ui/SelectEditValueTypes';
import SpecialInstructionsDialog from '../../ui/SpecialInstructionsDialog';
import PersonnelDialog from '../../ui/PersonnelDialog';
import SpendChartDialog from '../../ui/SpendChartDialog';

const Users = () => {
  const [emailEditable, setEmailEditable] = useState(false);
  const users = useSelector((state) => state.users);

  const { user } = useAuth();

  const { orderPartsPostSuccess } = useSelector((state) => state.orders);

  const { quotePartsPostSuccess } = useSelector((state) => state.quotes);

  const companies = useSelector((state) => state.companies);

  const dispatch = useDispatch();

  const apiRef = useGridApiRef();

  const [isSellerDialogOpen, setIsSellerDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [gridColumns, setGridColumns] = useState([]);
  const [properties, setProperties] = useState([]);

  const [specialInstructionDialogOpen, setSpecialInstructionDialogOpen] = useState({
    open: false,
    data: null
  });

  const [personnelDialogOpen, setPersonnelDialogOpen] = useState({
    open: false,
    data: null
  });

  const [spendChartDialogOpen, setSpendChartDialogOpen] = useState({
    open: false,
    data: null
  });

  useEffect(() => {
    if (!users.loading) {
      if (user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN) {
        dispatch(listSellersUsers(user));
      } else {
        dispatch(listUsers(user));
      }
    }
  }, []);

  useEffect(() => {
    if (users?.data) {
      setProperties(users?.data);
    }
  }, [users.data]);

  useEffect(() => {
    if (!users.loading && users.data.length > 0) {
      const showSellersList = ['PREMIUM_MARKETPLACE_ADMIN', 'PREMIUM_ADMIN'];
      let columnsList = columns(renderCell, renderOrdersCell, renderQuotesCell, user.type, renderActions, renderCompaniesCell, renderAccessLevelCell, emailEditable, renderSellersCell, renderInventoryCell, renderValueCell, renderSpecialInstructionsCell, renderPersonnelCell, renderSpendCell);
      if (user.type !== USER_TYPE.PREMIUM_ADMIN) {
        const filterTerms = ['actions'];
        columnsList = columnsList.filter(obj => !filterTerms.includes(obj.field));
      }

      if (!showSellersList.includes(user.type)) {
        const filterTerms = ['sellers'];
        columnsList = columnsList.filter(obj => !filterTerms.includes(obj.field));
      }

      setGridColumns(columnsList);
      apiRef.current.updateColumns(columnsList);
    }
  }, [users.data, emailEditable]);

  useEffect(() => {
    if (orderPartsPostSuccess && !users.loading) {
      dispatch(listUsers(user));
    }
  }, [orderPartsPostSuccess]);

  useEffect(() => {
    if (quotePartsPostSuccess && !users.loading) {
      dispatch(listUsers(user));
    }
  }, [quotePartsPostSuccess]);

  useEffect(() => {
    if (companies.data.length === 0) {
      dispatch(listCompanies(user));
    }
  }, []);

  useEffect(() => {
    if (properties[0]?.firstName === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'firstName', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'firstName');
        }, 50);
      }, 50);
    }
  }, [properties?.length]);

  const renderOrdersCell = (_props) => {
    const orders = _props.row.orders;
    const emailAddress = _props.row.emailAddress;
    return (
      <>
        {orders.length > 0 && (
          <UserCartButton
            data={orders}
            modalComponent={
              <UsersOrders
                userOrder={_props.row}
                title={emailAddress}
                companyId={_props.row.companyId}
                userId={_props.row._id}
              />
            }
            cartColor="success"
          />
        )}
      </>
    );
  };
  const renderQuotesCell = (_props) => {
    const quotes = _props.row.quotes;
    const emailAddress = _props.row.emailAddress;
    return (
      <>
        {quotes.length > 0 && (
          <UserCartButton
            data={quotes}
            modalComponent={
              <UsersQuotes
                userQuote={_props.row}
                title={emailAddress}
                companyId={_props.row.companyId}
              />
            }
            cartColor="rgba(0, 0, 0, 0.2)"
          />
        )}
      </>
    );
  };

  const checkIsValid = (data) => {
    if (!data?.firstName) {
      dispatch(
        enqueueSnackbar(
          'Please enter valid name',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }

    if (data?.isNewRow && !validateEmail(data?.emailAddress)) {
      dispatch(
        enqueueSnackbar(
          'Please enter valid email address',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }

    if (!data?.companyName) {
      dispatch(
        enqueueSnackbar(
          'Please select a company',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }

    if (!data?.type) {
      dispatch(
        enqueueSnackbar(
          'Please select access level',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }

    return true;
  };

  const handleSave = (row) => {
    if (checkIsValid(row)) {
      const body = {
        userDetails: {
          companyId: companies?.data.filter((item) => item.name === row?.companyName)[0]._id,
          type: row?.type,
          firstName: row?.firstName,
          emailAddress: row?.emailAddress,
          phone: row?.phone,
          orderApprovers: row?.orderApprovers,
          quoteApprovers: row?.quoteApprovers,
          admin: row?.admin,
          specialInstructions: row?.specialInstructions,
        },
      };

      if (!row?.isNewRow) {
        body.userId = row?._id;
        dispatch(updateUserDetails(body));
      } else {
        dispatch(addNewUser(body));
      }
    }
  };

  const renderActions = ({ row }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        variant="solid"
        onClick={() => {
          handleSave(row);
        }}
      >
        <SaveRounded />
      </IconButton>
      </Grid>
    );
  };

  const renderSpecialInstructionsCell = (props) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={
          props.row.type === 'CUSTOMER_RIG' && !props?.row?.isNewRow
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          sx={{ color: !_.isEmpty(props.row?.specialInstructions) && 'green' }}
          onClick={(_evt) => {
            setSpecialInstructionDialogOpen({
              open: true,
              data: props.row
            });
          }}
        >
          <PlaylistAddCheck fontSize='large' />
        </IconButton>
      </Grid>
    );
  };

  const renderPersonnelCell = (props) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={
          props.row.type === 'CUSTOMER_RIG' && !props?.row?.isNewRow
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          sx={{ color: !_.isEmpty(props.row?.orderApprovers || props.row?.quoteApprovers || props.row?.admins) && 'green' }}
          onClick={(_evt) => {
            setPersonnelDialogOpen({
              open: true,
              data: props.row
            });
          }}
        >
          <Person fontSize='large' />
        </IconButton>
      </Grid>
    );
  };

  const renderSpendCell = (props) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={
          (props.row.type === 'CUSTOMER_RIG' || props.row.type === 'CUSTOMER_USER') && !props?.row?.isNewRow
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          onClick={(_evt) => {
            setSpendChartDialogOpen({
              open: true,
              data: props.row
            });
          }}
        >
          <BarChart fontSize='large' />
        </IconButton>
      </Grid>
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderAccessLevelCell = (params) => {
    return <SelectEditValueTypes {...params} closeCellAfterSelect={true} data={Object.keys(USER_TYPE).map(item => item)} updateData={updateData} />;
  };

  const renderCompaniesCell = (params) => {
    return <SelectEditCompanyCell {...params} closeCellAfterSelect={true} data={companies?.data} updateData={updateData} />;
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField {...params} updateData={updateData} />;
  };

  const showSellersDialog = (user) => {
    setCurrentUser(user);
    setIsSellerDialogOpen(true);
  };

  const renderSellersCell = (params) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={
          params.row.type === USER_TYPE.CUSTOMER_RIG && !params?.row?.isNewRow
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          onClick={() => {
            showSellersDialog(params.row);
          }}
        >
          <SellRounded />
        </IconButton>
      </Grid>
    );
  };

  const renderInventoryCell = (params) => {
    return (
      <Grid
        container
        justifyContent="center"
        style={((user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN ||
           user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN || user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN) &&
           params.row.type === USER_TYPE.CUSTOMER_RIG)
          ? { visibility: 'visible' }
          : { visibility: 'hidden' }
        }
      >
        <IconButton
          variant="solid"
          onClick={(_evt) => {
            let url;
            if (user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN || user.type === USER_TYPE.PREMIUM_ADMIN) {
              url = `/Shop?companyId=${params.row._id}&vendorId=${user.companyId}&name=${params.row.firstName}`;
            }
            if (user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN) {
              url = `/Shop?companyId=${params.row._id}&name=${params.row.firstName}&customerRigUser=${params.row._id}`;
            }
            window.open(url, '_blank');
          }}
        >
          <WidgetsRounded />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={properties}
          autoHeight={false}
          columns={gridColumns}
          pageSize={100}
          disableStickyHeader={false}
          loading={users.loading}
          autoheight
          editMode="cell"
          height={'90vh'}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              density: true,
              export: false,
            },
          }}
          onSelectionModelChange={(ids) => {
            const isEmailEditable = apiRef?.current.getSelectedRows().get(ids[0])?.isNewRow || false;
            setEmailEditable(isEmailEditable);
          }}
        />
      </Grid>
      <SellersModalDialog
        isOpen={isSellerDialogOpen}
        onActionClick={() => {}}
        onCloseClick={() => {
          setIsSellerDialogOpen(false);
        }}
        selectedUser={currentUser}
      />
      {specialInstructionDialogOpen.open && (
        <SpecialInstructionsDialog
          isOpen={specialInstructionDialogOpen.open}
          data={specialInstructionDialogOpen.data}
          onCloseClick={() => {
            setSpecialInstructionDialogOpen({
              open: false,
              data: {}
            });
          }}
          handleSave={handleSave}
        />
      )}
       {personnelDialogOpen.open && (
        <PersonnelDialog
          open={personnelDialogOpen.open}
          handleClose={() => {
            setPersonnelDialogOpen({
              open: false,
              data: {}
            });
          }}
          rowData={personnelDialogOpen.data}
          handleSave={handleSave}
        />
       )}
        {spendChartDialogOpen.open && (
        <SpendChartDialog
          open={spendChartDialogOpen.open}
          handleClose={() => {
            setSpendChartDialogOpen({
              open: false,
              data: {}
            });
          }}
          rowData={spendChartDialogOpen.data}
          type='user'
        />
        )}
    </Grid>
  );
};

export default Users;
