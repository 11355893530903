import React from 'react';
import { Avatar, CardHeader, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { CancelPresentationRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import ModalDialog from '../../../ui/ModalDialog';
import ImageZoom from '../../../ui/ImageZoom';

const RigsDetailedPhotoCard = ({
  handleClose,
  data,
  open,
}) => {
  const getModalTitle = () => {
    return (
            <>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <CardHeader
                            sx={{ p: 0, m: 0 }}
                            avatar={
                                <Avatar sx={{ bgcolor: data.avatarColor }} aria-label="recipe">
                                    {data.avatarLetter}
                                </Avatar>
                            }
                            title={data.title}
                            subheader={data.email}
                            titleTypographyProps={{ variant: 'h6', noWrap: true, align: 'left' }}
                            subheaderTypographyProps={{ align: 'left' }}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={handleClose}
                        >
                            <CancelPresentationRounded fontSize='large' />
                        </IconButton>
                    </Grid>
                </Grid>
            </>
    );
  };

  return (
      <ModalDialog
          title={getModalTitle()}
          isOpen={open}
          onClose={handleClose}
          maxWidth="md"
      >
          <Grid container spacing={2} marginTop="20px" minHeight="450px" justifyContent="center">
              <Grid container sx={{ width: '100%', justifyContent: 'center' }}>
                  <ImageZoom>
                      <CardMedia
                          component="img"
                          height="500px"
                          image={data.image}
                          alt={data.title}
                          sx={{ objectFit: 'contain' }}
                      />
                  </ImageZoom>
              </Grid>
              <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ p: 4 }}
              >
                  {data.comment}
              </Typography>
          </Grid>
      </ModalDialog>
  );
};

RigsDetailedPhotoCard.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.any
};
export default RigsDetailedPhotoCard;
