/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton, gridClasses, Box } from '@mui/material';
import ModalDialog from '../ui/ModalDialog/index';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './style';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { renderCell } from '../ui/Datagrid/table_config';
import { addNewSurveyRow, createSurvey, deleteSurveyRow, importWellsSurveyCSVFIle, listSurvey, updateSurvey } from '../../store/actions/wellsSurvey';
import SelectEditInputTextField from '../ui/SelectEditInputTextField';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import FileUploadDialog from '../ui/FileUploadDialog';
import DeleteConfirmationModal from '../deleteConfirmationModal';
const SurveyModal = ({
  onCloseClick,
  surveyData,
  isOpen
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const survey = useSelector((state) => state.wellsSurvey);
  const apiRef = useGridApiRef();
  const [nonEditableFields, setNonEditableFields] = useState(false);
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [uploadOpen, setUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();

  useEffect(() => {
    if (!survey.loading) {
      dispatch(listSurvey(surveyData?.row?._id));
    }
  }, []);

  useEffect(() => {
    if (survey?.data) {
      setProperties(survey?.data);
    }
  }, [survey?.data]);

  useEffect(() => {
    if (properties[0]?.md === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'md', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'md');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const handleAddSurvey = () => {
    dispatch(addNewSurveyRow());
  };

  const removeSurvey = (row) => {
    if (row?.isNewRow) {
      const updatedSurvey = properties.filter(item => item._id !== row?._id);
      setProperties(updatedSurvey);
      dispatch(listSurvey(surveyData?.row?._id));
    } else {
      dispatch(deleteSurveyRow(row?._id, surveyData?.row?._id));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const showError = (message, rowId) => {
    dispatch(enqueueSnackbar(message, 'error'));
    setInvalidRows(prev => ({ ...prev, [rowId]: true }));
    return false;
  };

  const checkMD = (row, index, properties) => {
    if (index === 0 && row.md !== 0) {
      return showError('The first entered directional survey should only be 0.0', row._id);
    }
    if (index > 0 && row.md <= properties[index - 1].md) {
      return showError(`The measured depth at ${row.md} unit is less than the previous MD, please revise the input data`, row._id);
    }
    if (index < properties.length - 1 && row.md >= properties[index + 1].md) {
      return showError(`The measured depth at ${row.md} unit is greater than the next MD, please revise the input data`, row._id);
    }
    return true;
  };

  const checkInc = (row) => {
    if (row.inc < 0 || row.inc > 120) {
      return showError(`The inclination at depth ${row.md} unit should be between 0 to 120 degrees. Please revise the input data`, row._id);
    }
    return true;
  };

  const checkAz = (row) => {
    if (row.az < 0 || row.az > 360) {
      return showError(`The azimuth at depth ${row.md} unit should be between 0 to 360 degrees. Please revise the input data`, row._id);
    }
    return true;
  };

  const validateSurveyData = (row) => {
    const rowIndex = properties.findIndex(item => item._id === row._id);
    const lastRow = properties[properties.length - 1];

    if (row.isNewRow) {
      if (properties.length === 1) {
        return row.md === 0 || showError('The first entered directional survey should only be 0.0', row._id);
      }
      if (!checkMD(row, properties.length, properties) || !checkInc(row, lastRow) || !checkAz(row)) {
        return false;
      }
    } else {
      if (!checkMD(row, rowIndex, properties) || !checkInc(row, rowIndex > 0 ? properties[rowIndex - 1] : null) || !checkAz(row)) {
        return false;
      }
    }
    return true;
  };

  const checkSurveyDataValues = (row) => {
    const invalidFields = [];
    if (row?.az === undefined || row?.az === null || row?.az === '') invalidFields.push('Azimuth');
    if (row?.inc === undefined || row?.inc === null || row?.inc === '') invalidFields.push('Inclination');
    if (row?.md === undefined || row?.md === null || row?.md === '') invalidFields.push('Measured Depth');

    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      for (const key in row) {
        if (typeof row[key] === 'string' && !isNaN(row[key])) {
          row[key] = Number(row[key]);
        }
      }
      return validateSurveyData(row);
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter the following details: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveSurvey = (row) => {
    if (checkSurveyDataValues(row)) {
      const keysToExtract = ['md', 'inc', 'az'];

      const extracted = Object.fromEntries(
        Object.entries(row).filter(([key]) => keysToExtract.includes(key))
      );

      if (row?.isNewRow) {
        dispatch(createSurvey(extracted, surveyData?.row?._id));
      } else {
        dispatch(updateSurvey(extracted, surveyData?.row?._id, row?._id));
      }
    }
  };

  const handleOpenUploadModal = () => {
    setUploadOpen(true);
  };

  const getModalTitle = () => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {surveyData?.row?.name || ''}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setProperties([]);
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const renderActions = ({
    row
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={survey?.loading}
          onClick={() => {
            saveSurvey(row);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={survey?.loading}
          onClick={() => {
            setDeleteAction(true);
            setDeleteRow(row);
          }}
        >
          <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: Number(value)
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const handleUploadSuccess = () => {
    setUploadOpen(false);
  };

  const handleUploadCsv = (selectedFile) => {
    dispatch(importWellsSurveyCSVFIle(selectedFile, surveyData?.row?._id, setIsLoading, handleUploadSuccess));
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  if (apiRef.current === null) apiRef.current = {};
  return (
    <ModalDialog
      title={getModalTitle()}
      isOpen={isOpen}
      onClose={onCloseClick}
      dialogClass={classes.dialogModal}
    >
      <Grid container>
        <Box className={classes.customTool}>
          <Box>
          <Typography variant='button' fontWeight='700' className={survey.loading || properties[0]?.isNewRow ? classes.disableIcon : ''} onClick={handleAddSurvey}>+ Add</Typography>
          </Box>
          <Box>
          <Typography
            variant="body1"
            fontWeight="500"
            sx={{ marginLeft: '8px', marginRight: '8px', fontSize: '17px' }}
          >
            |
          </Typography>
          </Box>
          <Box>
          <label className={survey.loading ? classes.disableImportLabel : classes.importLabel} onClick={() => {
            handleOpenUploadModal();
          }}>Import</label>
          </Box>
        </Box>
        <Grid item xs={12}>
        {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={removeSurvey}
            handleClose={setDeleteAction}
            deleteRow={deleteRow}
          />
        }
          <Datagrid
            apiRef={apiRef}
            data={properties}
            sx={{
              marginLeft: '-6px',
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontSize: '1.2rem',
                fontWeight: '900 !important',
              },
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            loading={survey.loading}
            columns={columns(renderCell, renderActions, nonEditableFields, renderValueCell)}
            onRowClick={(params, evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            disableStickyHeader={false}
            autoHeight={false}
            autoheight
            height={'50vh'}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            pageSize={100}
            onSelectionModelChange={(ids) => {
              const editableField = apiRef?.current.getSelectedRows().get(ids[0])?.isNewRow || false;
              setNonEditableFields(editableField);
            }}
            editMode="cell"
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
          />
        </Grid>
        {uploadOpen && (
          <FileUploadDialog
          open={uploadOpen}
          handleClose={() => {
            setUploadOpen(false);
          }}
          isLoading={isLoading}
          handleUpload={handleUploadCsv}
        />
        )}
      </Grid>
    </ModalDialog>
  );
};
SurveyModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  surveyData: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
SurveyModal.defaultProps = {
  surveyData: {}
};
export default SurveyModal;
