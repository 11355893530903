import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import classNames from 'classnames';
import styles from './styles';
import { CardMedia } from '@mui/material';
import ButtonComponent from '../../Button';

import placeHolder from '../../../assets/images/placeholder.png';

const DescriptionImageModalDialog = ({
  image,
  isOpen,
  onClose,
  dialogClass,
  isFullScreen,
  data
}) => {
  const classes = styles();

  const imageHandler = (img, pn) => {
    if (pn && img.includes('placeholder.png')) {
      const imageName = img.substring(0, img.lastIndexOf('/') + 1);
      return `${imageName}${pn}.png`;
    }
    if (!img.includes('placeholder.png')) {
      return img;
    }
    return placeHolder;
  };

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      className={classNames(dialogClass, classes.dialogModal)}
      fullWidth={isFullScreen}
      maxWidth={'xl'}
    >
        <DialogContent className={classes.dialogContent} dividers>
        <CardMedia
          component="img"
          image={imageHandler(image, data?.PN)}
          onError={e => {
            e.target.src = placeHolder;
          }}
          alt={image}
          sx={{ objectFit: 'contain', width: 350, height: 350 }}
        />
        </DialogContent>
        <DialogActions>
        <ButtonComponent
        label='Close'
        variant={'contained'}
        size={'small'}
        onClick={onClose}
        />
        </DialogActions>
    </Dialog>
  );
};

DescriptionImageModalDialog.propTypes = {
  image: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  dialogClass: PropTypes.string,
  isFullScreen: PropTypes.bool,
  data: PropTypes.object
};

DescriptionImageModalDialog.defaultProps = {
  image: null,
  isOpen: false,
  onClose: null,
  dialogClass: null,
  isFullScreen: false,
  data: {}
};

export default DescriptionImageModalDialog;
