const masterColumn = [
  {
    field: 'RigName_Number',
    headerName: 'Name',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'RigLatitudeWGS84',
    headerName: 'Latitude',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'RigLongitudeWGS84',
    headerName: 'Longitude',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'LeaseName',
    headerName: 'Lease',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'ENVBasin',
    headerName: 'ENV Basin',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  }
];

const additionalColumnList = (renderCell, renderActions, renderEdit) => [
  {
    field: 'surveys',
    headerName: 'Surveys',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderEdit,
  },
  {
    field: 'geometry',
    headerName: 'Geometry',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderEdit,
  },
  {
    field: 'drill_string',
    headerName: 'Drill String',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderEdit,
  },
  {
    field: 'drilling_fluid',
    headerName: 'Drilling Fluid',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderEdit,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];

export { masterColumn, additionalColumnList };
