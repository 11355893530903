/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import styles from './styles';
import { masterColumns, detailColumns } from './table_config';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { listOrderParts, downloadOrderPDF, updateOrderList, downloadProofOfDelivery } from '../../store/actions/orders';
import { isFullArray } from '../../utils/validators';
import ModalDialog from './../ui/ModalDialog/index';
import { useAuth } from './../../hooks/useAuth';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { DeleteRounded, ContentCopy, CancelPresentationRounded, FileUploadRounded, ReceiptRounded } from '@mui/icons-material';
import { renderCell } from '../ui/Datagrid/table_config';
import OrderProcessingModalDialog from '../ui/OrderProcessingModalDialog';
import DescriptionImageModalDialog from '../ui/DescriptionImageModalDialog';
import { USER_TYPE } from '../../utils/constants';
import RenderStatusField from '../ui/RenderStatusField';
import ConfirmStatusUpdateDialog from '../ui/ConfirmStatusUpdateDialog';
import { postProofOfDelivery } from '../../store/actions/users';

const UsersOrders = ({
  isOpen,
  title,
  data,
  onCloseClick,
  companyId,
  onModalCloseClick,
  userOrder,
  userId
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { loading, greenPartsDetails, orderPartsPostSuccess } = useSelector(
    (state) => state.orders
  );

  const [selectedRowId, setSelectedRowId] = useState(null);
  const orders = data;
  const [partIDs, setPartIds] = useState([]);
  const [cart, setCart] = useState([]);
  const [counter, setCounter] = useState(0);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const apiRef = useGridApiRef();
  const classes = styles();
  const ordersRedux = useSelector((state) => state.orders);
  const usersRedux = useSelector((state) => state.users);
  const [partImage, setPartImage] = useState({
    open: false,
    data: {}
  });
  const [rowData, setRowData] = useState({});
  const [dialogTitle, setDialogTitle] = useState(null);
  const [selectionModel, setSelectionModel] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [selectRowToUpload, setSelectRowToUpload] = useState(null);

  const fileInputRef = React.useRef(null);

  const handleButtonClick = (row) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setSelectRowToUpload(row);
    }
  };

  useEffect(() => {
    let OrderCompanyId = companyId;
    if ((user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN || user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN) && (userOrder.type === USER_TYPE.CUSTOMER_RIG)) {
      OrderCompanyId = userOrder._id;
    }
    dispatch(listOrderParts(OrderCompanyId, partIDs));
  }, [partIDs, counter]);

  useEffect(() => {
    if (orderPartsPostSuccess) {
      dispatch({
        type: REDUX_ACTIONS.ORDERS_RESET,
      });
      onCloseClick();
      setIsProcessing(false);
    }
  }, [orderPartsPostSuccess]);

  useEffect(() => {
    setPartIds(orders[0].partIDs);
    setDialogTitle(createDialogTitle(orders[0]));
    setRowData(orders[0]);
    setSelectionModel(orders[0]._id);
    setSelectedRowId(orders[0]._id);
  }, []);

  useEffect(() => {
    if (isFullArray(greenPartsDetails)) {
      const cartDetails = partIDs
        .map((id) => {
          const part = greenPartsDetails.find((part) => part._id === id);
          if (part) {
            return {
              ...part,
              vendorName: orders.find(order => order.partIDs.includes(id)).vendorName
            };
          }
          return null;
        })
        .filter((part) => !!part);
      setCart(cartDetails);
    }
  }, [greenPartsDetails]);

  const createDialogTitle = ({ orderNumber, timestamp, quantity, price }) => {
    return `Date: ${timestamp}, Order Id: ${orderNumber}, Quantity: ${quantity}, Total Price: $${price.toFixed(2)}`;
  };

  const handleDownloadOrderPDF = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (selectedRowId !== null) {
      dispatch(downloadOrderPDF(rowData.orderNumber));
    }
  };

  const closeLoadingDialog = () => {
    setLoadingDialog(false);
    onModalCloseClick();
  };

  const getModalTitle = () => {
    return (
      <>
       <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {title}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                onCloseClick();
                onModalCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const removeRow = (_e) => {
    const allRows = Array.from(apiRef.current.getRowModels());
    const currentCart = apiRef.current.getAllRowIds();

    const newList = [];
    for (const key of apiRef.current.getSelectedRows().keys()) {
      const deleteRow = currentCart.filter((row) => row === key);
      newList.push(deleteRow[0]);
    }

    const filtered = allRows.filter((x) => {
      return !newList.includes(x[0]);
    });

    const newCart = [];
    for (const row of filtered) {
      newCart.push(row[1]);
    }
    setCart(newCart);
    const cartTotalPrice = newCart
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    setDialogTitle(
    `Date: ${rowData.timestamp}, Order Id: ${rowData.orderNumber}, Quantity: ${newCart.length}, Total Price: $${cartTotalPrice.toFixed(2)}`
    );
  };

  const duplicateRow = (_e) => {
    const newCart = [...cart];
    for (const row of apiRef.current.getSelectedRows().values()) {
      newCart.push(row);
    }
    setCart(newCart);
    const cartTotalPrice = newCart
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    setDialogTitle(
    `Date: ${rowData.timestamp}, Order Id: ${rowData.orderNumber}, Quantity: ${newCart.length}, Total Price: $${cartTotalPrice.toFixed(2)}`
    );
  };

  const renderActions = ({
    id
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        variant="solid"
        onClick={() => {
          apiRef.current.selectRow(id, true);
          removeRow();
        }}
      >
        <DeleteRounded />
      </IconButton>
        <IconButton
          variant="solid"
          onClick={() => {
            apiRef.current.selectRow(id, true);
            duplicateRow();
          }}
        >
          <ContentCopy />
        </IconButton>
      </Grid>
    );
  };

  const uploadProofOfDelivery = (e) => {
    const file = e.target.files[0];
    if (file) {
      dispatch({
        type: REDUX_ACTIONS.USERS_POD_UPLOAD_LOADING,
        payload: selectRowToUpload._id
      });
      dispatch(postProofOfDelivery(file, userId, selectRowToUpload));
      setSelectRowToUpload(null);
    }
  };

  const handleDownloadPod = (order) => {
    dispatch(downloadProofOfDelivery(order.orderNumber));
  };

  const renderProofOfDelivery = ({ row, id }) => {
    return (
      <Grid item container justifyContent="center">
        {
          row?.POD
            ? <IconButton
              disabled={ordersRedux.printState === 'loading'}
              onClick={() => {
                handleDownloadPod(row);
              }}
            >
              <ReceiptRounded />
            </IconButton>
            : <label htmlFor={`fileUpload-${id}`}>
                <IconButton
                  disabled={
                    !(user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || user.type === USER_TYPE.PREMIUM_ADMIN) || usersRedux.PODSave
                  }
                  onClick={() => {
                    if (!row?.POD) {
                      handleButtonClick(row);
                    }
                  }}
                >
                  <FileUploadRounded />
                </IconButton>
              </label>
        }
      </Grid>
    );
  };

  const renderDescriptionCell = ({ value, row }) => (
    <Typography
      onClick={() => setPartImage({
        open: true, data: row
      })}
      style={{ fontSize: '12px', fontWeight: 'normal', textWrap: 'wrap', cursor: 'pointer' }}
    >{value}
    </Typography>
  );

  if (apiRef.current === null) apiRef.current = {};

  const handleConfirmStatus = (orderNumber, status, id) => {
    setSelectedOrder({ orderNumber, status, id });
    setConfirmDialog(true);
  };

  const handleConfirm = () => {
    dispatch(updateOrderList(orders, selectedOrder, setSelectedOrder, 'orders'));
  };

  return (
    <>
    <ModalDialog
      title={getModalTitle()}
      isOpen={isOpen}
      onClose={() => {
        onCloseClick();
        onModalCloseClick();
      }}
      dialogClass={classes.dialogModal}
    >
    <input type='file'style={{ display: 'none' }} id={'fileUpload'} ref={fileInputRef} accept="image/* , application/pdf" onChange={uploadProofOfDelivery} />
      <Grid container>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={orders}
            columns={masterColumns(
              ({ id, row }) => <RenderStatusField disabled={user?.type !== 'CUSTOMER_MANAGER'} id={id} row={row} handleStatus={handleConfirmStatus} />,
              renderProofOfDelivery
            )}
            style={{ fontWeight: 'bold' }}
            onSelectionModelChange={(e) => {
              setSelectionModel((e));
            }}
            selectionModel={selectionModel}
            onRowClick={(params, evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              setDialogTitle(createDialogTitle(params.row));
              setRowData(params.row);
              if (selectedRowId !== params.id) {
                setCart([]);
                setPartIds(params.row.partIDs);
                setSelectedRowId(params.id);
                setCounter((state) => state + 1);
              }
            }}
            disableStickyHeader={false}
            autoHeight={false}
            autoheight
            height={'30vh'}
            pageSize={100}
            toolbar={{
              options: {
                columns: false,
                filters: true,
                density: false,
                export: false,
              },
            }}
          />
          <Box className={classes.orderTitleBox}>
            <Grid item xs={11}>
              <Typography textAlign="left" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Order Details: {dialogTitle}
              </Typography>
            </Grid>
            <Grid item xs={1} className={classes.printButtonWrapper}>
              { selectedRowId !== null
                ? <IconButton
                  edge="end"
                  onClick={handleDownloadOrderPDF}
                  disabled={ordersRedux.printState === 'loading'}
                >
                  <PrintOutlinedIcon fontSize='large'/>
                </IconButton>
                : null
              }
            </Grid>
          </Box>
          <Grid item xs={12}>
            <Datagrid
              loading={loading}
              data={cart}
              apiRef={apiRef}
              className={classes.selectedOrderDatagrid}
              columns={detailColumns(renderCell, renderActions, user.type, renderDescriptionCell)}
              onRowClick={(_, evt) => {
                evt.stopPropagation();
              }}
              getRowId={(row) => `${Math.random(1000)}_${row._id}`}
              disableStickyHeader={false}
              autoHeight={false}
              autoheight
              height={'35vh'}
              pageSize={100}
              toolbar={{
                options: {
                  columns: false,
                  filters: true,
                  density: false,
                  export: false,
                },
              }}
              getRowHeight={() => 'auto'}
              sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalDialog>
    {partImage.open &&
      <DescriptionImageModalDialog
        data={partImage.data}
        image={partImage.data.Image}
        isOpen={partImage.open}
        onClose={() => setPartImage({
          open: false,
          data: {}
        })}
      />
    }
    <OrderProcessingModalDialog
      isOpen={loadingDialog}
      isProcessing={isProcessing}
      closeLoadingDialog={closeLoadingDialog}
    />
    {confirmDialog
      ? (
        <ConfirmStatusUpdateDialog
          open={confirmDialog}
          handleClose={() => {
            setConfirmDialog(false);
            setSelectedOrder(null);
          }}
          selectedData={selectedOrder}
          handleConfirm={handleConfirm}
        />
        )
      : null}
    </>
  );
};

export default React.memo(UsersOrders);
