import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  flexAlign: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    padding: '5px'
  }
}));

export default styles;
