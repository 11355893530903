import React, { useState, useEffect } from 'react';
import store from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { listAlarms, listSignals, deleteAlarmRow, saveAlarms } from '../../store/actions/alarms';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import columns from './table_config';
import { renderCell } from '../ui/Datagrid/table_config';
import Datagrid from '../ui/Datagrid';
import JobsGrid from '../jobs/jobs_grid';
import SelectEditInputCell from '../../components/ui/SelectEditInputCell';
import { DeleteRounded, SaveRounded, Email } from '@mui/icons-material';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import DeleteConfirmationModal from '../deleteConfirmationModal';
import RecipientsDialog from '../ui/RecipientsDialog';

const Alarms = () => {
  const dispatch = useDispatch();
  const alarms = useSelector((state) => state.alarms);
  const jobs = useSelector((state) => state.jobs);

  const [signals, setSignals] = useState(jobs.currentJob.signals);
  const [deleteAction, setDeleteAction] = useState(false);

  const [recipientsDialog, setRecipients] = useState({
    open: false,
    data: null
  });

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (!alarms.loading) {
      if (jobs.currentJob._id) {
        dispatch(listAlarms(jobs.currentJob._id));
        dispatch(listSignals(jobs.currentJob._id));
      } else {
        dispatch({
          type: REDUX_ACTIONS.ALARMS_LIST_SUCCESS,
          payload: {
            data: [],
            paginatedElements: 0,
          },
        });
      }
    }
  }, [jobs.currentJob._id]);

  useEffect(() => {
    setSignals(jobs.currentJob.signals);
  }, [jobs.currentJob.signals]);

  const renderSignalCell = (params) => {
    return <SelectEditInputCell {...params} data={signals} />;
  };

  const removeAlarm = () => {
    dispatch(deleteAlarmRow());
    setDeleteAction(false);
  };

  const saveAlarm = () => {
    dispatch(saveAlarms());
  };

  const handleRecipientsSave = (data) => {
    const alarmsArray = [...alarms.data];
    const index = alarmsArray.findIndex(item => item._id === data._id);
    if (index !== -1) {
      alarmsArray[index] = data;
    }
    dispatch({
      type: REDUX_ACTIONS.ALARMS_LIST_SUCCESS,
      payload: {
        data: alarmsArray,
        paginatedElements: alarmsArray.length,
      },
    });
    saveAlarm();
  };

  const renderRecipientsCell = (params) => {
    return (
      <Grid
        container
        justifyContent="center"
      >
        <IconButton
          variant="solid"
          sx={{ color: params.row?.recipients?.length && 'green' }}
          disabled={!params.row?.signal}
          onClick={(_evt) => {
            setRecipients({
              open: true,
              data: params.row
            });
          }}
        >
          <Email />
        </IconButton>
      </Grid>
    );
  };

  const renderActions = ({
    id
  }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        variant="solid"
        onClick={() => {
          apiRef.current.selectRow(id, true);
          dispatch({
            type: REDUX_ACTIONS.SET_CURRENT_ALARM,
            payload: {
              alarmId: id,
            },
          });
          setDeleteAction(true);
        }}
      >
        <DeleteRounded/>
      </IconButton>
        <IconButton
          variant="solid"
          onClick={() => {
            apiRef.current.selectRow(id, true);
            dispatch({
              type: REDUX_ACTIONS.SET_CURRENT_ALARM,
              payload: {
                alarmId: id,
              },
            });
            saveAlarm();
          }}
        >
          <SaveRounded />
        </IconButton>
      </Grid>
    );
  };

  const addingNewRow = () => {
    const { alarms } = store.getState();
    const newAlarms = [...alarms.data];
    const current = [];
    for (const row of apiRef.current.getSelectedRows().values()) {
      current.push(row);
    }

    const newList = newAlarms.map((a) => {
      return a._id === current[0]._id ? current[0] : a;
    });

    dispatch({
      type: REDUX_ACTIONS.ALARMS_LIST_SUCCESS,
      payload: {
        data: newList,
        paginatedElements: newList.length,
      },
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          data={alarms.data}
          autoHeight={false}
          columns={columns(renderCell, renderSignalCell, renderActions, renderRecipientsCell)}
          loading={false}
          pageSize={100}
          disableStickyHeader={false}
          height={'44vh'}
          apiRef={apiRef}
          onCellEditStop={() => {
            addingNewRow();
          }}
          onRowClick={({ row: alarm }) => {
            dispatch({
              type: REDUX_ACTIONS.SET_CURRENT_ALARM,
              payload: {
                alarmId: alarm._id,
              },
            });
          }}
        />
        {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={removeAlarm}
            handleClose={setDeleteAction}
          />
        }
      </Grid>
      <Grid item xs={12}>
        <JobsGrid
          height={'44vh'}
          stickyHeader={false}
          autoHeight={false}
          pageSize={6}
        />
      </Grid>
      {recipientsDialog.open && (
        <RecipientsDialog
          open={recipientsDialog.open}
          handleClose={() => {
            setRecipients({
              open: false,
              data: {}
            });
          }}
          rowData={recipientsDialog.data}
          handleSave={handleRecipientsSave}
        />
      )}
    </Grid>
  );
};

Alarms.propTypes = {
  row: PropTypes.object,
};

export default Alarms;
