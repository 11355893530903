import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  spendChartData: {
    loading: false,
    data: [],
    errorMessage: '',
    filterType: 'year',
    average: 0,
    total: 0
  }
};

const spendReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.SPEND_CHART_DATA:
      return {
        ...state,
        spendChartData: {
          ...state.spendChartData,
          loading: false,
          data: action.payload,
          average: (action.payload?.reduce((sum, point) => sum + point.y, 0) / action.payload?.length).toFixed(2),
          total: action.payload?.reduce((total, { y }) => total + y, 0).toFixed(2)
        }
      };
    case REDUX_ACTIONS.SPEND_CHART_DATA_LOADING:
      return {
        ...state,
        spendChartData: {
          ...state.spendChartData,
          loading: true,
          data: [],
          average: 0,
          total: 0
        }
      };
    case REDUX_ACTIONS.SPEND_CHART_DATA_ERROR:
      return {
        ...state,
        spendChartData: {
          ...state.spendChartData,
          loading: false,
          data: [],
          average: 0,
          total: 0,
          errorMessage: action.payload.message,
        }
      };
    case REDUX_ACTIONS.SPEND_CHART_SET_FILTER_TYPE:
      return {
        ...state,
        spendChartData: {
          ...state.spendChartData,
          filterType: action.payload
        }
      };

    case REDUX_ACTIONS.SPEND_CHART_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default spendReducer;
