import fetchAPI from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';
import store from '../store';
import * as ENVIRONMENT from '../../shared/config/config';
import { USER_TYPE } from '../../utils/constants';

export const listUsers = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.USERS_LIST_LOADING,
  });

  const body = { collectionName: 'Users' };

  return fetchAPI
    .post('users/users', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.USERS_LIST_SUCCESS,
        payload: {
          users: data.data.map(user => ({
            ...user,
            orders: [...user.orders].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
            quotes: [...user.quotes].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          })),
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.USERS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const fetchCompanyUsers = (companyId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.COMPANIES_USERS_LIST_LOADING,
  });

  const body = { query: { companyId } };

  return fetchAPI
    .post('users/fetch/companyUsers', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      const emailIds = data?.data?.map(item => item.emailAddress);
      dispatch({
        type: REDUX_ACTIONS.COMPANIES_USERS_LIST_SUCCESS,
        payload: emailIds
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.COMPANIES_USERS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const listUsersWithFilter = (body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.USERS_LIST_LOADING,
  });
  const query = { ...body };
  return fetchAPI
    .post('users/fetch', { query })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.USERS_LIST_SUCCESS,
        payload: {
          users: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.USERS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const addNewUsersRow = () => (dispatch) => {
  const { users, currentUser } = store.getState();
  const newUsers = [...users.data];
  const nextId = newUsers.length - 1;

  function generateRandom() {
    const length = 24;
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const newRow = {
    firstName: '',
    emailAddress: '',
    phone: '',
    companyId: '',
    companyName: '',
    type: '',
    orders: [],
    quotes: [],
    userId: currentUser.userId,
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newUsers.unshift(newRow);

  dispatch({
    type: REDUX_ACTIONS.USERS_LIST_SUCCESS,
    payload: {
      users: newUsers,
      paginatedElements: newUsers.length,
    },
  });
};

export const updateUserDetails = (body) => (dispatch) => {
  return fetchAPI
    .put('users', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listUsers());
      dispatch(
        enqueueSnackbar(
          'User Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const addNewUser = (body) => (dispatch) => {
  return fetchAPI
    .post('users/user-create', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listUsers());
      dispatch(
        enqueueSnackbar(
          'User Added Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const postProofOfDelivery = (file, userId, order) => async (dispatch) => {
  const { _id } = order;
  dispatch({
    type: REDUX_ACTIONS.USERS_POD_UPLOAD_LOADING,
    payload: _id
  });
  const formData = new FormData();
  const { currentUser } = store.getState();
  const BASE_URL = ENVIRONMENT.API_URL;
  formData.append('file', file);
  const payload = {
    input: {
      orderNumber: order.orderNumber
    },
    user: {
      type: currentUser.type,
      companyId: currentUser.companyId,
      userId: currentUser.userId,
    },
  };
  formData.append('payload', JSON.stringify(payload));
  try {
    const response = await fetch(`${BASE_URL}orders/pod/upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    if (!response.ok) {
      return Promise.reject(response);
    }
    dispatch({
      type: REDUX_ACTIONS.USERS_SET_POD,
      payload: { userId, orderId: _id, POD: true },
    });
    dispatch(
      enqueueSnackbar(
        'POD Uploaded Successfully',
        'success',
        new Date().getTime() + Math.random()
      )
    );
  } catch (error) {
    const errorMessage = await parseError(error);
    dispatch(
      enqueueSnackbar(
        errorMessage,
        'error',
        new Date().getTime() + Math.random()
      )
    );
  }
};

export const fetchSalesReps = (type) => (dispatch) => {
  const { currentUser } = store.getState();
  dispatch({
    type: REDUX_ACTIONS.SALES_REPS_LIST_LOADING,
  });

  const query = {
    companyId: type === USER_TYPE.CUSTOMER_USER ? currentUser?.companyId : undefined,
    userId: type === USER_TYPE.CUSTOMER_RIG ? currentUser?.userId : undefined,
  };

  const body = { query };

  return fetchAPI
    .post('marketplace/fetch/salesReps', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.SALES_REPS_LIST_SUCCESS,
        payload: {
          data: data?.data,
          paginatedElements: data?.data.length
        }
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.SALES_REPS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
