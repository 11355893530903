import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import {
  AddAlertRounded,
  AspectRatioRounded
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import {
  addNewAlarmRow,
} from '../../../../store/actions/alarms';
import styles from './styles';

const AlarmsControls = () => {
  const dispatch = useDispatch();
  const { preferences } = useSelector((state) => state.currentUser);
  const classes = styles();
  const jobs = useSelector((state) => state.jobs);

  const handleAspectRatio = useAspectRatio();

  const handleAddToAlarms = () => {
    dispatch(addNewAlarmRow());
  };

  return (
    <Box sx={{ marginLeft: 'auto' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box className={classes.container}>
            <Box>
            <IconButton
            size="large"
            aria-label="orders"
            onClick={handleAddToAlarms}
            disabled={!jobs?.currentJob?._id}
          >
            <AddAlertRounded />
          </IconButton>
            </Box>
            <Box>
            <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlarmsControls;
