import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  loadingContainer: {
    display: 'grid',
    placeItems: 'center',
    height: '100vh'
  },
  gridItemContainer: {
    borderBottom: '1px solid #ccc',
    padding: '14px 0'
  },
  gridItemImage: {
    width: '2.8em',
    height: '2.8em',
    borderRadius: '100%',
    objectFit: 'cover',
    marginRight: '10px',
  },
  gridItemText: {
    fontSize: '16px',
  }
}));

export default styles;
