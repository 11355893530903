import { AspectRatioRounded, DirectionsRunRounded } from '@mui/icons-material';
import { Box, FilledInput, FormControl, IconButton, InputLabel, TextField, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useEffect, useState } from 'react';
import styles from './styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { CALENDER_PROPS } from '../../../../utils/constants';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useLocation } from 'react-router-dom';
import { listCheckInsDrill } from '../../../../store/actions/checkInsDrill';

const CheckInsDrillControls = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);
  const classes = styles();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);
  const [search, setSearch] = useState(null);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);

  const handleSearchTerm = (e) => {
    setSearch(e.target.value);
  };

  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };

  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    const start = showDateTimePicker ? selectedStartDateTime : null;
    const end = showDateTimePicker ? selectedEndDateTime : null;
    dispatch({
      type: REDUX_ACTIONS.CHECKINS_DRILL_SEARCH_CONTROL,
      payload: {
        searchKey: search,
        startDate: start,
        endDate: end,
      },
    });
    dispatch({
      type: REDUX_ACTIONS.CHECKINS_DRILL_SET_CURRENT_PAGE,
      payload: 1
    });
    dispatch(
      listCheckInsDrill({
        search,
        start,
        end,
      }
      )
    );
  };

  useEffect(() => {
    setSelectedStartDateTime(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    setSelectedEnDateTime(new Date());
  }, [location.pathname]);

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <Box>
        <FormControl>
          <InputLabel
            htmlFor="outlined-adornment-search"
            inputprops={{ classes: { root: classes.searchBar } }}
          >
            Search
          </InputLabel>
          <FilledInput
            className={classes.searchBar}
            id="outlined-adornment-search"
            type="text"
            label="Search"
            value={search || ''}
            onChange={handleSearchTerm}
          />
        </FormControl>
        </Box>
        {showDateTimePicker
          ? (
        <Box className={classes.dateContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            disableFuture
            label="Start Date"
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            textField={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            label="End Date"
            {...CALENDER_PROPS}
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            format="MM/dd/yyyy hh:mm:ss a"
            disabled={!selectedStartDateTime}
            minDateTime={selectedStartDateTime}
            disableFuture
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        </Box>
            )
          : null}
        <Box className={classes.runActionContainer}>
          <IconButton
            size="large"
            onClick={() => setShowDateTimePicker(!showDateTimePicker)}
          >
            <DateRangeIcon />
          </IconButton>
        </Box>
        <Box className={classes.runActionContainer}>
          <IconButton
            disabled={showDateTimePicker && (!selectedStartDateTime || !selectedEndDateTime)}
            size="large"
            onClick={handleSearch}
          >
            <DirectionsRunRounded />
          </IconButton>
        </Box>
        <Box className={classes.aspectRatioContainer}>
        <IconButton
          edge="end"
          onClick={() => {
            handleAspectRatio(!preferences.aspectChanged);
          }}
        >
          <AspectRatioRounded />
        </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default CheckInsDrillControls;
