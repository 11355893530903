import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  zoomImageContainer: {
    position: 'relative',
    padding: 0,
    margin: 0
  },
  zoomControls: {
    position: 'absolute',
    zIndex: 1000,
    background: 'rgba(0,0,0,0.5)',
    bottom: 0,
    right: 0
  },
  zoomControlIcon: {
    color: '#FFF'
  }
}));
export default styles;
