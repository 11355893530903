import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  customTool: {
    marginLeft: '12px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    marginTop: '10px',
    position: 'sticky',
    zIndex: 100,
    backgroundColor: '#fff',
  },
  disableImportLabel: {
    fontWeight: 700,
    fontSize: '0.875rem',
    cursor: 'pointer',
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  dialogModal: {
    '& .MuiPaper-root': {
      marginLeft: '10px',
      '& > div:first-child': {
        padding: '4px 11px !important',
      },
      maxWidth: '1050px',
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    }
  },
  disableIcon: {
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.26)'
  },
  importLabel: {
    fontWeight: 700,
    fontSize: '0.875rem',
    cursor: 'pointer',
  },
  invalidRow: {
    backgroundColor: '#ffcccc !important'
  }
}));
export default styles;
