import { Typography, Grid, Box, List, ListItem, Divider } from '@mui/material';
import styles from './styles';
import Premium from '../../assets/images/PRO logo PMS green only.svg';

export default function PrivacyPolicy() {
  const classes = styles();
  return (
    <Grid container justifyContent="center">
      <Grid item container xs={12} justifyContent="center">
        <Box
          component="img"
          alt="Logo"
          src={Premium}
          className={classes.logo}
        />
        <Divider className={classes.divider}/>
      </Grid>
      <Grid item container xs={12} justifyContent="center" flex flexDirection={'column'} className={classes.contentWrapper}>
      <Typography variant="body1" className={classes.boldtext}>
          Privacy Policy <br />
          Effective Date: 10/10/2024
        </Typography>
        <Typography variant="body1" className={classes.boldtext}>
          1. Introduction
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {'Welcome to Premium Oilfield Technologies, LLC ("we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website and ecommerce stores.'}
        </Typography>

        <Typography variant="body1" className={classes.boldtext}>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {'We may collect the following types of information:'}
        </Typography>
        <List className={classes.list} disablePadding>
          <ListItem disablePadding className={classes.listItem}><span className={classes.boldlisttext}>Personal Information:</span> Name, email address, phone number, billing and shipping address, payment information, and any other information you provide to us.</ListItem>
          <ListItem disablePadding className={classes.listItem}><span className={classes.boldlisttext}>Technical Information:</span>  IP address, browser type, operating system, and other technical information about your device and usage patterns.</ListItem>
          <ListItem disablePadding className={classes.listItem}><span className={classes.boldlisttext}>Transactional Information:</span> Details about your purchases, order history, and payment information.</ListItem>
          <ListItem disablePadding className={classes.listItem}><span className={classes.boldlisttext}>Cookies and Tracking Technologies:</span> Information collected through cookies, web beacons, and similar technologies.</ListItem>
        </List>

        <Typography variant="body1" className={classes.boldtext}>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {'We use the information we collect for the following purposes:'}
        </Typography>
        <List className={classes.list} disablePadding>
          <ListItem disablePadding className={classes.listItem}>To process and fulfill your orders.</ListItem>
          <ListItem disablePadding className={classes.listItem}>To communicate with you about your orders, inquiries, and other customer service needs.</ListItem>
          <ListItem disablePadding className={classes.listItem}>To improve our website, products, and services.</ListItem>
          <ListItem disablePadding className={classes.listItem}>To send you promotional offers, newsletters, and other marketing communications (you can opt-out at any time).</ListItem>
          <ListItem disablePadding className={classes.listItem}>To comply with legal obligations and protect our rights and interests.</ListItem>
        </List>

        <Typography variant="body1" className={classes.boldtext}>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {'We may share your information with:'}
        </Typography>
        <List className={classes.list} disablePadding>
          <ListItem disablePadding className={classes.listItem}><span className={classes.boldlisttext}>Service Providers:</span> Third-party service providers who assist us with payment processing, shipping, marketing, and other business operations.</ListItem>
          <ListItem disablePadding className={classes.listItem}><span className={classes.boldlisttext}>Legal Requirements:</span>  When required by law or to protect our rights and interests.</ListItem>
          <ListItem disablePadding className={classes.listItem}><span className={classes.boldlisttext}>Business Transfers:</span> In the event of a merger, acquisition, or sale of all or part of our business.</ListItem>
        </List>

        <Typography variant="body1" className={classes.boldtext}>
          5. Data Security
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {'We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.'}
        </Typography>

        <Typography variant="body1" className={classes.boldtext}>
          6. Your Rights
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {'You have the right to:'}
        </Typography>
        <List className={classes.list} disablePadding>
          <ListItem disablePadding className={classes.listItem}>Access and review your personal information.</ListItem>
          <ListItem disablePadding className={classes.listItem}>Request corrections to your personal information.</ListItem>
          <ListItem disablePadding className={classes.listItem}>Request the deletion of your personal information.</ListItem>
          <ListItem disablePadding className={classes.listItem}>Opt-out of receiving marketing communications.</ListItem>
        </List>
        <Typography variant="body1" className={classes.text}>
          {'To exercise these rights, please contact us at [Insert Contact Information].'}
        </Typography>

        <Typography variant="body1" className={classes.boldtext}>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {'We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically.'}
        </Typography>

        <Typography variant="body1" className={classes.boldtext}>
          8. Contact Us
        </Typography>
        <Typography variant="body1" className={classes.text}>
          If you have any questions or concerns about this Privacy Policy, please contact us at: <br />

          Premium Oilfield Technologies, LLC <br />
          10600 W Sam Houston Pkwy N <br />
          Houston, TX 77064, United States <br />
          Phone: +1 (281) 679-6500 <br />
          Email: troy@premiumoilfield.com
        </Typography>

      </Grid>
    </Grid>
  );
}
