import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  logo: {
    width: '300px',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '20px',
    width: '100%',
  },
  text: {
    marginBottom: '10px',
    fontSize: 13
  },
  boldtext: {
    marginTop: '15px',
    fontSize: 14,
    fontWeight: 'bold'
  },
  boldlisttext: {
    fontWeight: 'bold'
  },
  list: {
    listStyleType: 'disc',
    marginLeft: '20px',
    marginBottom: '10px',
  },
  listItem: { display: 'list-item', fontSize: 13 },
  contentWrapper: {
    padding: '20px'
  }
}));

export default styles;
