import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../ui/Datagrid';
import { gridClasses } from '@mui/x-data-grid';
import columns from './table_config';
import { downloadTransactionHistory, listTransactionHistory } from '../../store/actions/transactionHistory';
import { Grid, TablePagination, Typography } from '@mui/material';
import { renderCell } from '../ui/Datagrid/table_config';
import PropTypes from 'prop-types';
import RenderJsonField from '../ui/RenderJsonField';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { createEpicorReportInventoryPDF } from '../../store/actions/epicor/epicorReports';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import { useGridApiRef } from '@mui/x-data-grid-pro';

// eslint-disable-next-line react/prop-types
function CustomPagination({ currentPage, limit, totalCount, handlePagination, loading }) {
  return (
    <TablePagination
      component="div"
      count={totalCount}
      page={currentPage}
      onPageChange={(event, page) => !loading && handlePagination(page)}
      rowsPerPage={limit}
      rowsPerPageOptions={[]}
    />
  );
}

const TransactionHistory = () => {
  const transactionHistory = useSelector((state) => state.transactionHistory);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState([]);

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (!transactionHistory.loading && transactionHistory.data.length === 0) {
      dispatch(listTransactionHistory());
    }
  }, []);

  useEffect(() => {
    if (filter.length === 0 && transactionHistory.data.length) {
      dispatch(listTransactionHistory());
    }
  }, [filter]);

  const handleDownloadPDF = (transactionOrderNum) => {
    dispatch({
      type: REDUX_ACTIONS.TRANSACTION_HISTORY_PRINT_STATE,
      payload: {
        printState: true,
      },
    });
    if (transactionOrderNum?.length) {
      transactionOrderNum?.forEach((orderNum) => {
        dispatch(createEpicorReportInventoryPDF(orderNum));
      });
    } else {
      dispatch(
        enqueueSnackbar(
          'Error in downloading file',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    }
    dispatch({
      type: REDUX_ACTIONS.TRANSACTION_HISTORY_PRINT_STATE,
      payload: {
        printState: false,
      },
    });
  };

  const handlePagination = (page) => {
    if (page < transactionHistory.currentPage) {
      dispatch(listTransactionHistory({ page: transactionHistory.currentPage - 1 }));
      dispatch({
        type: REDUX_ACTIONS.TRANSACTION_HISTORY_SET_CURRENT_PAGE,
        payload: transactionHistory.currentPage - 1
      });
    } else {
      dispatch(listTransactionHistory({ page: transactionHistory.currentPage + 1 }));
      dispatch({
        type: REDUX_ACTIONS.TRANSACTION_HISTORY_SET_CURRENT_PAGE,
        payload: page + 1
      });
    }
    apiRef.current.setFilterModel({
      items: filter
    });
    apiRef.current.scrollToIndexes({
      rowIndex: 0,
      colIndex: 0,
    });
  };

  const renderOrderField = ({ row }) => {
    const transactionOrderNum = row?.response?.value?.map(item => item?.SaleOrder?.OrderNum);
    return (
        <Typography style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1B75D0' }} onClick={ () => {
          handleDownloadPDF(transactionOrderNum);
        }}>{transactionOrderNum?.length ? transactionOrderNum.toString() : ''}</Typography>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={transactionHistory?.data}
          columns={columns(renderCell, RenderJsonField, renderOrderField)}
          loading={transactionHistory.loading}
          disableStickyHeader={false}
          onRowClick={({ row }) => {
            const transactionOrderNum = row?.response?.value?.map(item => item?.SaleOrder?.OrderNum);
            if (transactionOrderNum?.length) {
              dispatch({
                type: REDUX_ACTIONS.TRANSACTION_HISTORY_ORDER_NUM,
                payload: {
                  orderNum: transactionOrderNum,
                },
              });
            } else {
              dispatch({
                type: REDUX_ACTIONS.TRANSACTION_HISTORY_ORDER_NUM,
                payload: {
                  orderNum: null,
                },
              });
            }
          }}
          height={'90vh'}
          getRowId={(row) => row.grid_id}
          autoPageSize
          autoHeight={false}
          pagination={false}
          hideFooter
          experimentalFeatures={{ newEditingApi: true }}
          filterMode="server"
          getRowHeight={() => 'auto'}
          serverExport={() => dispatch(downloadTransactionHistory())}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: {
                enabled: true,
                options: true,
                serverExportMode: true
              },
            },
          }}
          onFilterModelChange={ (model) => {
            setFilter(model.items);
            const items = model.items.map(filter => {
              if (filter.columnField === 'userEmail' || filter.columnField === 'response' || filter.columnField === 'payload') {
                return { ...filter, columnField: 'details.' + filter.columnField };
              } else if (filter.columnField === 'orderNumber') {
                return { ...filter, columnField: 'details.response.value.SaleOrder.OrderNum' };
              } else {
                return filter;
              }
            });
            const filterModels = { ...model, items };
            dispatch({
              type: REDUX_ACTIONS.TRANSACTION_HISTORY_SET_FILTER,
              payload: filterModels.items.length ? filterModels : {}
            });
          }}
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            const sort = sortModel.reduce((result, item) => {
              if (item.field === 'userEmail' || item.field === 'payload' || item.field === 'response') {
                result['details.' + item.field] = item.sort;
              } else if (item.field === 'orderNumber') {
                result['details.response.value.SaleOrder.OrderNum'] = item.sort;
              } else {
                result[item.field] = item.sort;
              }
              return result;
            }, {});
            dispatch({
              type: REDUX_ACTIONS.TRANSACTION_HISTORY_SET_SORT,
              payload: sort
            });
            dispatch({
              type: REDUX_ACTIONS.TRANSACTION_HISTORY_SET_CURRENT_PAGE,
              payload: 1
            });
            dispatch(listTransactionHistory());
          }}
        />
        <CustomPagination
          currentPage={transactionHistory.currentPage - 1}
          limit={transactionHistory.limit}
          totalCount={transactionHistory.totalCount}
          handlePagination={handlePagination}
          loading={transactionHistory.loading}
        />
      </Grid>
    </Grid>
  );
};

TransactionHistory.propTypes = {
  row: PropTypes.object,
};

export default TransactionHistory;
