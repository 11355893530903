const columns = (renderCell, renderActions, renderValueCell) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'od',
    headerName: 'OD (in)',
    width: 120,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'id',
    headerName: 'ID (in)',
    width: 120,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'tj_od',
    headerName: 'TJ OD (in)',
    width: 135,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'tj_id',
    headerName: 'TJ ID (in)',
    width: 135,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'len',
    headerName: 'LENGTH (ft)',
    width: 135,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'tj_len%',
    headerName: 'TJ LENGTH (%)',
    width: 170,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => renderActions({ row: params.row, index: params.api.getRowIndex(params.id) }),
  },
];
export default columns;
