import fetchAPI from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';
import { listWells } from './wells';
import * as ENVIRONMENT from '../../shared/config/config';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const listWindow = (wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_WINDOW_LIST_LOADING,
  });
  const body = {
    query: {
      wellsInfoId: wellId
    }
  };
  return fetchAPI
    .post('wells/entity/window/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.WELLS_WINDOW_LIST_SUCCESS,
        payload: {
          data: data.data.sort((a, b) => a.md - b.md),
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addNewWindowRow = () => (dispatch) => {
  const { wellsWindow } = store.getState();
  const newWindow = [...wellsWindow.data];
  const nextId = newWindow.length - 1;
  const newRow = {
    md: '',
    pp: '',
    fp: '',
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newWindow.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.WELLS_WINDOW_LIST_SUCCESS,
    payload: {
      data: newWindow,
      paginatedElements: newWindow.length,
    },
  });
};

export const createWindow = (body, wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_WINDOW_LIST_LOADING,
  });
  delete body._id;
  const input = {
    query: {
      wellsInfoId: wellId
    },
    input: {
      ...body,
    }
  };
  return fetchAPI
    .post('wells/entity/window/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Window Details Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listWindow(wellId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listWindow(wellId));
    });
};

export const updateWindow = (body, wellId, rowId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_WINDOW_LIST_LOADING,
  });
  const input = {
    query: {
      _id: rowId,
      wellsInfoId: wellId
    },
    input: {
      ...body
    }
  };
  return fetchAPI
    .put('wells/entity/window', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Window Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listWindow(wellId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listWindow(wellId));
    });
};

export const deleteWindowRow = (deleteWindowId, wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_WINDOW_LIST_LOADING,
  });
  const body = {
    query: {
      _id: deleteWindowId,
      wellsInfoId: wellId
    }
  };
  return fetchAPI
    .deleteAction('wells/entity/window', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Window Details Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listWindow(wellId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listWindow(wellId));
    });
};

export const importWellsWindowCSVFIle = (file, wellId, setIsLoading, handleUploadSuccess) => async (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_WINDOW_LIST_LOADING,
  });
  const formData = new FormData();
  const { currentUser } = store.getState();
  const ipDetails = {
    ip: currentUser?.ip,
  };
  const BASE_URL = ENVIRONMENT.API_URL;
  formData.append('file', file);
  const payload = {
    query: {
      wellsInfoId: wellId
    },
    user: {
      type: currentUser.type,
      companyId: currentUser.companyId,
      userId: currentUser.userId,
      ...(ipDetails?.ip && { ...ipDetails })
    },
  };
  formData.append('payload', JSON.stringify(payload));
  setIsLoading(true);
  try {
    const response = await fetch(`${BASE_URL}wells/entity/window/import`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    if (response.status !== 200) {
      const errorData = await response.json();
      const errorMessage = errorData.message;
      throw new Error(errorMessage);
    }
    dispatch(
      enqueueSnackbar(
        'CSV Imported Successfully',
        'success',
        new Date().getTime() + Math.random()
      )
    );
    setIsLoading(false);
    handleUploadSuccess();
    dispatch(listWindow(wellId));
    dispatch(listWells());
  } catch (error) {
    let errorMessage = '';

    if (typeof error === 'string') {
      errorMessage = error;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    } else if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = 'An unknown error occurred';
    }

    const extractedMessage = errorMessage.split(' at importWindowsDataFromCSV')[0].trim();

    dispatch(
      enqueueSnackbar(
        extractedMessage,
        'error',
        new Date().getTime() + Math.random()
      )
    );
    setIsLoading(false);
    dispatch(listWindow(wellId));
  }
};
