import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';

export const listDbwVsTarget = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.DBW_VS_TARGET_LIST_LOADING,
  });
  return fetch
    .post('enverus/DbwTarget')
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(async (data) => {
      const { startDate, endDate } = data[0];

      dispatch({
        type: REDUX_ACTIONS.DBW_VS_TARGET_SET_DATE,
        payload: {
          selectedStartDateTime: startDate,
          selectedEndDateTime: endDate
        }
      });

      dispatch({
        type: REDUX_ACTIONS.DBW_VS_TARGET_LIST_SUCCESS,
        payload: {
          data,
          paginatedElements: data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.DBW_VS_TARGET_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const syncDbwVsTarget = ({ startDate, endDate }) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.DBW_SYNC_LOADING,
    payload: 'Generating Report.. Please wait'
  });

  dispatch({
    type: REDUX_ACTIONS.DBW_VS_TARGET_LIST_LOADING,
  });

  dispatch({
    type: REDUX_ACTIONS.DBW_VS_TARGET_DIVISION_RESET
  });
  dispatch({
    type: REDUX_ACTIONS.CHECKINS_RESET
  });
  const body = {
    input: {
      startDate,
      endDate
    }
  };
  return fetch
    .post('enverus/syncDbwTarget', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then(async(res) => {
      dispatch({
        type: REDUX_ACTIONS.DBW_SYNC_LOADING,
        payload: 'Report complete'
      });
      await dispatch(
        enqueueSnackbar(
          res.message || 'Sync Successfully Completed',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      await dispatch(listDbwVsTarget());
    })
    .catch(async (error) => {
      dispatch({
        type: REDUX_ACTIONS.DBW_SYNC_LOADING,
        payload: error.message || 'Error syncing on DbwVsTarget'
      });
      dispatch(
        enqueueSnackbar(
          error.message || 'Error syncing on DbwVsTarget',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};
